class DocumentFormatter {

  static mask(number) {
    const unmasked = (number || '').replace(/[^0-9]/g, '');
    if (!unmasked) {
      return '';
    }
    
    if (unmasked.length === 11) {
      return `${unmasked.substr(0, 3)}.${unmasked.substr(3, 3)}.${unmasked.substr(6, 3)}-${unmasked.substr(9, 2)}`;
    } else if (unmasked.length === 14) {
      return `${unmasked.substr(0, 2)}.${unmasked.substr(2, 3)}.${unmasked.substr(5, 3)}/${unmasked.substr(8, 4)}-${unmasked.substr(12, 2)}`;
    }

    return unmasked;
  }

}

export default DocumentFormatter;