import Axios from "axios";
import UriHelper from "../Util/UriHelper";

const baseUrl = '/api/sso/profile/'
const userdataKey = 'userdata';

class ProfileService {

  static async get({ clientId, redirectUri }) {
    const url = UriHelper.buildUri(baseUrl, {
      clientId: clientId,
      redirectUri: redirectUri,
      t: Date.now(),
    });
    return await Axios.get(url);
  }

  static async update(request) {
    return await Axios.post(baseUrl, request);
  }

  static async signUp(request) {
    return await Axios.post(baseUrl + 'signup', request);
  }

  static async sendSignUpCode(request) {
    return await Axios.post(baseUrl + 'send-signup', request);
  }

  static async confirmedUser(request) {
    return await Axios.post(baseUrl + 'confirmed-user', request);
  }

  static async changeMail(request) {
    return await Axios.post(baseUrl + 'change-email', request);
  }

  static async confirmAccount(request) {
    return await Axios.post(baseUrl + 'confirm-account', request);
  }

  static storeData(data) {
    localStorage.setItem(userdataKey, JSON.stringify(data));
  }

  static getUserData() {
    return JSON.parse(localStorage.getItem(userdataKey));
  }

  static async forgotEmail(request) {
    return await Axios.post(baseUrl + 'forgot-email', request);
  }

}

export default ProfileService;
