
import React from 'react';
import './Footer.css';

class Footer extends React.Component {

  render() {
    return (
      <footer>
        <div className="logo">
          <img height="30" src="/img/logo_nsc_total.png" alt="logo nsc total" />
          <div>
            <span>&copy; 2020 NSC Comunicações</span>
          </div>
        </div>
      </footer>
    );
  }

}

export default Footer;

