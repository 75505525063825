import Axios from "axios";

const baseUrl = '/api/v1/registrationEventToday/'

class RegistrationEventTodayService {

  static async verifyRegistration(registration) {
    return await Axios.post(baseUrl + `verify/${registration}`);
  }

  static async signUp(request) {
    return await Axios.post(baseUrl + 'signup', request);
  }

}

export default RegistrationEventTodayService;
