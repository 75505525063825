
import React from 'react';
import AuthStepper from './AuthStepper'
import './Header.css';

class Header extends React.Component {

  render() {
    return (
      <header>
        <div className={`logo-wrapper ${typeof (this.props.step) != 'number' && 'center-logo'}`}>
         <a href='https://nsctotal.com.br/'>  <img height="40" src='/img/nsc_total_color.png' alt="Logo NSC Total" /> </a>
        </div>
        {typeof (this.props.step) == 'number' && <div className="stepper-container">
          <AuthStepper activeStep={this.props.step} />
        </div>}
      </header>
    );
  }

}

export default Header;
