import * as yup from 'yup';
import { CPF, CNPJ } from 'cpf_cnpj';

function makeRequired(validation, required, message) {
  if (required) {
    validation = validation.required(message || 'Campo obrigatório');
  }

  return validation;
}

class Validation {

  static any({ required, message }) {
    const validation = yup.string();
    return makeRequired(validation, required, message);
  }

  static isTrue({ message }) {
    return yup.string()
      .required(message)
      .matches(/^(true|1)$/i, message);
  }

  static cellphone({ required }) {
    const validation = yup.string()
      .matches(/^\([1-9][0-9]\)\s9[0-9]{4}-[0-9]{4}$/, 'Informe um celular válido');

    return makeRequired(validation, required);
  }

  static email({ required }) {
    const validation = yup.string().trim()
      .email('Informe um e-mail válido')

    return makeRequired(validation, required);
  }

  static document({ required }) {
    const validation = yup.string()
      .required('CPF/CNPJ é obrigatório')
      .matches(/^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}[-]?\d{2})|(\d{3}\.?\d{3}\.?\d{3}[-]?\d{2})$/, 'Informe um documento válido')
      .test('cpf', 'Informe um CPF válido', value => !value || value.length > 14 || CPF.isValid(value))
      .test('cnpj', 'Informe um CNPJ válido', value => !value || value.length <= 14 || CNPJ.isValid(value));

    return makeRequired(validation, required);
  }

  static birthdate({ required }) {
    const validation = yup.string()
      .matches(/^(((0[1-9]|[12]\d|3[01])\/(0[13578]|1[02])\/((19|[2-9]\d)\d{2}))|((0[1-9]|[12]\d|30)\/(0[13456789]|1[012])\/((19|[2-9]\d)\d{2}))|((0[1-9]|1\d|2[0-8])\/02\/((19|[2-9]\d)\d{2}))|(29\/02\/((1[6-9]|[2-9]\d)(0[48]|[2468][048]|[13579][26])|(([1][26]|[2468][048]|[3579][26])00))))$/, 'Informe uma data válida')
    return makeRequired(validation, required);
  }

  static username({ required }) {
    const validation = yup.string()
      .matches(/^[0-9a-zA-Z\-_.]*$/, 'Informe um nome de usuário válido')
      .max(1000, "Não pode ter mais de 1000 caracteres");

    return makeRequired(validation, required);
  }

  static properName({ required }) {
    const validation = yup.string()
      .matches(/^[^'"!@#$%¨&*()\-_+=\\/<>,:;?|^~[\]{}]*$/, 'Não pode conter caracteres especiais')
      .matches(/^[^0-9]*$/, 'Não pode conter números')
      .max(50, "Deve ter no máximo 50 caracteres");

    return makeRequired(validation, required);
  }

  static password({ required }) {
    const validation = yup.string()
      .min(6, 'Deve ter no mínimo 6 caracteres.')
      .max(50, "Não pode ter mais de 50 caracteres");

    return makeRequired(validation, required);
  }

  static veficationCode({ required }) {
    const validation = yup.string()
      .min(8, 'O código deve ter no máximo 8 caracteres')
      .min(6, 'O código deve ter no mínimo 6 caracteres')
      .matches(/^[0-9]+$/, 'O código pode conter somente números');

    return makeRequired(validation, required);
  }
}

export default Validation;
