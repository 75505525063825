import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import RegistrationService from '../Service/ProfileService';
import MaskedInput from 'react-text-mask'
import './Profile.css';
import ProfileService from '../Service/ProfileService';
import Loader from '../Components/Loader';
import PhoneFormatter from '../Util/PhoneFormatter';
import Validation from '../Util/Validation';
import ErrorMessages from '../Util/ErrorMessages';
import UriHelper from '../Util/UriHelper';
import FormError from '../Components/FormError';
import Box from '../Components/Box';
import Checkbox from '../Components/Checkbox';
import DocumentFormatter from '../Util/DocumentFormatter';

class Profile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      user: {
        custom: {},
      },
      clientId: '',
      redirectUri: '',
      error: '',
    };

    this.states = [
      '', 'AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO',
      'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ',
      'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'
    ];

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  async componentDidMount() {
    const params = UriHelper.getParams(this.props.location.search);
    this.setState({ params });

    try {
      const result = await ProfileService.get({
        clientId: params.clientId,
        redirectUri: params.redirectUri,
        state: params.state,
      });

      this.setState({
        user: result.data,
        legalEntity: result.data.preferredUsername && result.data.preferredUsername.length === 14,
        cognito: result.data.provider === 'COGNITO',
        loaded: true,
      });
    } catch (e) {
      const uri = UriHelper.error({ code: e.response.status });
      this.props.history.push(uri);
    }
  }

  formatDate(date) {
    if (!date) {
      return false;
    }

    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + (d.getDate() + 1),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('/');
  }

  formatInvertDateTime(date) {
    var datePart = date.match(/\d+/g),
      day = datePart[0],
      year = datePart[2],
      month = datePart[1]

    return year + '-' + month + '-' + day
  }

  render() {
    return (
      <Box pageTitle="Conta" title="Conta NSC" description="Altere seus dados de cadastro">
        <Formik
          onSubmit={this.onFormSubmit}
          render={this.renderForm}
          enableReinitialize={true}
          initialValues={{
            firstName: this.state.user.firstName || '',
            lastName: this.state.user.lastName || '',
            email: this.state.user.email || '',
            document: DocumentFormatter.mask(this.state.user.preferredUsername),
            birthdate: this.state.user.birthdate ? this.formatDate(this.state.user.birthdate) : '',
            gender: this.state.user.gender || '',
            phone: this.state.user.phoneNumber ? PhoneFormatter.mask(this.state.user.phoneNumber) : '',
            city: this.state.user.custom.address_city || '',
            state: this.state.user.custom.address_state || '',
            tradingName: this.state.user.custom.trading_name || '',
            changePassword: false,
            password: '',
            newPassword: '',
            legalEntity: this.state.legalEntity || false,
            cognito: this.state.cognito || true,
          }}
          validationSchema={this.getFormValidationSchema} />
        <Loader loaded={this.state.loaded} />
      </Box>
    );
  }

  renderForm({ values, errors, touched, isSubmitting }) {
    return (
      <Form className="form">
        <div className="form-group">
          <label>Nome*</label>
          <ErrorMessage name="firstName" component="div" className="error-message" />
          <Field
            type="text"
            name="firstName"
            placeholder="Digite seu nome"
            disabled={!this.state.cognito}
            className={errors.firstName && touched.firstName ? 'error' : ''} />
        </div>
        <div className="form-group">
          <label>Sobrenome*</label>
          <ErrorMessage name="lastName" component="div" className="error-message" />
          <Field
            type="text"
            name="lastName"
            placeholder="Digite seu sobrenome"
            disabled={!this.state.cognito}
            className={errors.lastName && touched.lastName ? 'error' : ''} />
        </div>
        <div className="form-group">
          <label>CPF/CNPJ*</label>
          <ErrorMessage name="document" component="div" className="error-message" />
          <Field
            type="text"
            name="document"
            render={({ field }) => (
              <MaskedInput
                {...field}
                type="text"
                placeholder="Digite seu CPF ou CNPJ"
                className={errors.document && touched.document ? 'error' : ''}
                mask={v => v.replace(/[./\-_]+/g, '').length > 11
                  ? [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                  : [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
              />
            )} />
        </div>
        <div className="form-group">
          <label>E-mail*</label>
          <ErrorMessage name="email" component="div" className="error-message" />
          <Field
            type="text"
            name="email"
            autoCorrect="off"
            autoCapitalize="off"
            placeholder="Digite seu e-mail"
            disabled
            className={errors.email && touched.email ? 'error text-lower-case' : 'text-lower-case'} />
        </div>
      
        <div className="form-group">
          <label>Sexo</label>
          <Field
            name="gender"
            value={values.gender}
            component="select"
            className={errors.state && touched.state}>
            <option value="">Selecione</option>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
          </Field>
        </div>
        <div className="form-group">
          <label>Data de Nascimento</label>
          <ErrorMessage name="birthdate" component="div" className="error-message" />
          <Field
            type="text"
            name="birthdate"
            render={({ field }) => (
              <MaskedInput
                {...field}
                type="text"
                placeholder="Digite sua data de nascimento"
                className={errors.birthdate && touched.birthdate ? 'error' : ''}
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
              />
            )} />
        </div>
        <div hidden={!this.state.legalEntity}>
          <div className="form-group">
            <label>Nome fantasia*</label>
            <ErrorMessage name="tradingName" component="div" className="error-message" />
            <Field
              type="text"
              name="tradingName"
              placeholder="Digite o nome fantasia"
              disabled={!this.state.legalEntity}
              className={errors.tradingName && touched.tradingName ? 'error' : ''} />
          </div>
        </div>
        <div className="form-group">
          <label>Telefone celular*</label>
          <ErrorMessage name="phone" component="div" className="error-message" />
          <Field
            name="phone"
            render={({ field }) => (
              <MaskedInput
                {...field}
                type="text"
                placeholder="Digite seu telefone"
                className={errors.phone && touched.phone ? 'error' : ''}
                mask={['(', /[1-9]/, /[1-9]/, ')', ' ', /9/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              />
            )}
          />
        </div>
        <div className="form-group row">
          <div className="col col-8">
            <label>Cidade</label>
            <ErrorMessage name="city" component="div" className="error-message" />
            <Field
              type="text"
              name="city"
              placeholder="Digite sua cidade"
              className={errors.city && touched.city ? 'error' : ''} />
          </div>
          <div className="col col-4">
            <label>Estado</label>
            <ErrorMessage name="state" component="div" className="error-message" />
            <Field
              name="state"
              value={values.state}
              component="select"
              className={errors.state && touched.state}>
              {this.states.map((o) => (<option key={o} value={o}>{o}</option>))}
            </Field>
          </div>
        </div>
        <div hidden={this.state.user.provider !== 'COGNITO'}>
          <Field
            component={Checkbox}
            id="changePassword"
            type="checkbox"
            name="changePassword"
            label="Alterar senha"
            size="large" />
          <div className="form-group">
            <label>Senha atual</label>
            <ErrorMessage name="password" component="div" className="error-message" />
            <Field
              type="password"
              name="password"
              disabled={!values.changePassword}
              placeholder="Digite sua senha"
              className={errors.password && touched.password ? 'error' : ''} />
          </div>
          <div className="form-group">
            <label>Nova senha</label>
            <ErrorMessage name="newPassword" component="div" className="error-message" />
            <Field
              type="password"
              name="newPassword"
              disabled={!values.changePassword}
              placeholder="Digite sua nova senha"
              className={errors.newPassword && touched.newPassword ? 'error' : ''} />
          </div>
        </div>
        <Field type="hidden" name="legalEntity" />
        <FormError message={this.state.error} />
        <button className="btn-submit" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Aguarde' : 'Salvar'}
        </button>
      </Form>
    );
  }

  getFormValidationSchema() {
    return yup.object().shape({
      legalEntity: yup.boolean(),
      firstName: Validation.properName({ required: true }),
      lastName: Validation.properName({ required: true }),
      tradingName: yup.string().when('legalEntity', {
        is: true,
        then: Validation.properName({ required: true }),
      }),
      email: Validation.email({ required: true }),
      document: Validation.document({ required: true }),
      phone: Validation.cellphone({ required: true }),
      city: Validation.properName({ required: false }),
      birthdate: Validation.birthdate({ required: false }),
      state: Validation.any({ required: false }),
      changePassword: yup.boolean(),
      password: yup.string().when('changePassword', {
        is: true,
        then: Validation.password({ required: true }),
      }),
      newPassword: yup.string().when('changePassword', {
        is: true,
        then: Validation.password({ required: true }),
      }),
    });
  }

  async onFormSubmit(values, { setSubmitting }) {
    try {
      const { data } = await RegistrationService.update({
        clientId: this.state.params.clientId,
        redirectUri: this.state.params.redirectUri,
        responseType: this.state.params.responseType.toUpperCase(),
        user: {
          preferredUsername: values.document.replace(/[^0-9]/g, ''),
          email: values.email.toLowerCase(),
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phone ? PhoneFormatter.addCountryCode('55', values.phone) : null,
          gender: values.gender,
          birthdate: values.birthdate ? this.formatInvertDateTime(values.birthdate) : null,
          custom: {
            address_city: values.city,
            address_state: values.state,
            trading_name: values.tradingName,
          },
        },
        password: values.changePassword ? values.password : null,
        newPassword: values.changePassword ? values.newPassword : null,
      });

      if (data.status === 'SUCCESS') {
        const queryParams = this.state.params.responseType === 'code'
          ? { code: data.code, state: this.state.params.state }
          : { accessToken: data.accessToken, idToken: data.idToken, state: this.state.params.state }
        window.location.href = UriHelper.buildUri(this.state.params.redirectUri, queryParams);
      } else {
        this.setState({
          error: ErrorMessages.getDescription(data.status),
        });
      }
    } catch (error) {
      this.setState({
        error: ErrorMessages.getDescription(error.response.data.status),
      });
    }

    setSubmitting(false);
  }
}

export default Profile;
