
import React from 'react';
import './Dialog.css';

class Dialog extends React.Component {

    constructor(props) {
        console.log(props)
        super(props);
    }

    render() {
        return this.props.isOpen ? (
            <div className="background">
                <div className="dialog">
                    <p className="close-btn" onClick={this.props.onClose}>X</p>
                    {this.props.children}
                </div>
            </div>
        ) : null;
    }

}

export default Dialog;
