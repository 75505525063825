import React from 'react';
import SocialLogin from '../Components/SocialLogin';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import AuthenticationService from '../Service/AuthService';
import * as yup from 'yup';
import './Signup.css';
import Separator from '../Components/Separator';
import ProfileService from '../Service/ProfileService';
import Validation from '../Util/Validation';
import UriHelper from '../Util/UriHelper';
import ErrorMessages from '../Util/ErrorMessages';
import FormError from '../Components/FormError';
import Box from '../Components/Box';
import { Link } from 'react-router-dom';
import Checkbox from '../Components/Checkbox';
import MaskedInput from 'react-text-mask';
import AuthService from '../Service/AuthService';

class Signup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      params: {},
      error: '',
      facebookUrl: '',
      googleUrl: '',
      appleUrl: '',
      userExists: false,
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  async componentDidMount() {
    const params = UriHelper.getParams(this.props.location.search);
    this.setState({
      params: params,
    });

    const { data } = await AuthenticationService.getStatus(
      params.clientId,
      params.redirectUri,
      null,
      params.state, params.responseType.toUpperCase());

    if (data.status === 'AUTHENTICATED') {
      const uri = UriHelper.login(params);
      this.props.history.push(uri);
    } else {

      const isCodeResponse = params.responseType === 'code';
      const { data } = isCodeResponse
        ? await AuthService.createCode(params)
        : await AuthService.createCredentials(params);

      this.setState({
        facebookUrl: data.facebookUrl,
        googleUrl: data.googleUrl,
        appleUrl: data.appleUrl,
      });
    }
  }

  render() {
    return (
      <Box className="signup"
        pageTitle="Criar conta"
        title="Crie sua conta"
        logo={true}
        showImage={true}
        showBackButton={true}
        description={'Preencha os dados abaixo'}>
        <Formik
          onSubmit={this.onFormSubmit}
          render={this.renderForm}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            birthdate: '',
            document: '',
            password: '',
            mailingList: true,
          }}
          validationSchema={this.getFormValidationSchema} />

        {!!!this.state.params.fullSignup && <Separator text={<b>ou escolha uma opção para se cadastrar</b>} />}

        {!!!this.state.params.fullSignup && <SocialLogin
          facebookUrl={this.state.facebookUrl}
          googleUrl={this.state.googleUrl}
          appleUrl={this.state.appleUrl} />}
      </Box>
    );
  }

  renderForm({ errors, touched, isSubmitting }) {
    return (
      <Form className="form">
        <div className="form-group">
          <label>Nome*</label>
          <ErrorMessage name="firstName" component="div" className="error-message" />
          <Field
            type="text"
            name="firstName"
            placeholder="Digite seu nome"
            className={errors.firstName && touched.firstName ? 'error' : ''} />
        </div>
        <div className="form-group">
          <label>Sobrenome*</label>
          <ErrorMessage name="lastName" component="div" className="error-message" />
          <Field
            type="text"
            name="lastName"
            placeholder="Digite seu sobrenome"
            className={errors.lastName && touched.lastName ? 'error' : ''} />
        </div>
        <div className="form-group">
          <label>CPF/CNPJ*</label>
          <ErrorMessage name="document" component="div" className="error-message" />
          <Field
            type="text"
            name="document"
            render={({ field }) => (
              <MaskedInput
              {...field}
                type="text"
                placeholder="Digite seu CPF ou CNPJ"
                className={errors.document && touched.document ? 'error' : ''}
                mask={v => v.replace(/[./\-_]+/g, '').length > 11
                  ? [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                  : [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
              />
            )} />
        </div>
        <div className="form-group">
          <label>Telefone*</label>
          <ErrorMessage name="phone" component="div" className="error-message" />
          <Field
            type="text"
            name="phone"
            required
            render={({ field }) => (
              <MaskedInput
                {...field}
                type="text"
                placeholder="Digite seu telefone"
                mask={['(', /[1-9]/, /[1-9]/, ')', ' ', /9/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                className={errors.phone && touched.phone ? 'error' : ''}
              />
            )}
          />
        </div>
        <div className="form-group">
          <label>E-mail*</label>
          <ErrorMessage name="email" component="div" className="error-message" />
          <Field
            type="text"
            name="email"
            autoCorrect="off"
            autoCapitalize="off"
            placeholder="Digite seu e-mail"
            className={errors.email && touched.email ? 'error text-lower-case' : 'text-lower-case'} />
        </div>
        <div className="form-group">
          <label>Senha*</label>
          <ErrorMessage name="password" component="div" className="error-message" />
          <p className="alinhamento">
            <Field
              type="password"
              name="password"
              placeholder="Digite sua senha"
              className={errors.password && touched.password ? 'error' : ''} />
          </p>
        </div>
       

        {!!this.state.params.fullSignup && <div className="form-group">
          <label>Data de nascimento*</label>
          <ErrorMessage name="birthdate" component="div" className="error-message" />
          <Field
            type="text"
            name="birthdate"
            render={({ field }) => (
              <MaskedInput
                {...field}
                type="text"
                placeholder="Digite sua data de nascimento"
                mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
                className={errors.birthdate && touched.birthdate ? 'error' : ''}
              />
            )}
          />
        </div>}
        <p className="alinhamento">
          <div className="terms-message">
            <span>Efetuando o cadastro você estará concordando com os </span>
            <Link className="link" to="/terms" target="_blank">Termos de Uso</Link>
            <span> e a </span>
            <Link className="link" to="/privacy-policy" target="_blank">Política de Privacidade/Uso de Cookies</Link>
          </div>
          <Field component={Checkbox}
            name="mailingList"
            size="large"
            label="Desejo receber novidades e promoções por e-mail." />
          <div className="account-confirmation-text">Será enviado um e-mail para confirmação do seu cadastro.</div>
        </p>
        <div hidden={!this.state.userExists} className="user-exists">
          <span>Já existe um usuário com o e-mail informado, faça seu login </span>
          <Link className="link" to={UriHelper.loginLink(this.state.params)}> aqui </Link>
        </div>
        <FormError message={this.state.error} />
        <button className="btn-submit" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Aguarde' : 'Cadastrar'}
        </button>
      </Form>
    );
  }

  getFormValidationSchema() {
    const params = UriHelper.getParams(window.location.search);
    return yup.object().shape({
      firstName: Validation.properName({ required: true }),
      lastName: Validation.properName({ required: true }),
      email: Validation.email({ required: true }),
      password: Validation.password({ required: true }),
      phone: Validation.cellphone({ required: true }),
      birthdate: Validation.birthdate({ required: !!params.fullSignup }),
      document: Validation.document({ required: !!params.fullSignup }),
    });
  }

  async onFormSubmit(values, { setSubmitting }) {
    try {
      const { data } = await ProfileService.signUp({
        clientId: this.state.params.clientId,
        redirectUri: this.state.params.redirectUri,
        state: this.state.params.state,
        responseType: this.state.params.responseType.toUpperCase(),
        password: values.password,
        user: {
          email: values.email.toLowerCase(),
          firstName: values.firstName,
          lastName: values.lastName,
          phoneNumber: values.phone && values.phone.length ? ('+55' + values.phone.replace(/[^0-9]/g, '')) : null,
          preferredUsername: values.document ? values.document.replace(/[^0-9]/g, '') : null,
          birthdate: values.birthdate ? values.birthdate.split('/').reverse().join('-') : null,
          custom: {
            mailing_lists: values.mailingList ? 'NSC;' : '',
            status: 'LGPD;'
          }
        },
      });

      if (data.status === 'SUCCESS') {
        ProfileService.storeData({ statusSign: true, email: values.email.toLowerCase(), user: data.userSub });
        const queryParams = this.state.params.responseType === 'code'
          ? { ...this.state.params }
          : { accessToken: data.accessToken, idToken: data.idToken, ...this.state.params };
        const url = UriHelper.confirmSignup(queryParams);
        this.props.history.push(url);
      }
      else {
        this.setState({
          error: data.status === 'USERNAME_EXISTS' ? null : ErrorMessages.getDescription(data.status),
          userExists: data.status === 'USERNAME_EXISTS',
        });
      }
    } catch (error) {
      const errorDescription = ErrorMessages.getDescription(error.response.data.status);
      this.setState({
        error: errorDescription,
      });
    }

    setSubmitting(false);
  }
}

export default Signup;
