import Axios from "axios";
import UriHelper from "../Util/UriHelper";

const baseUrl = '/api/sso/password-recovery/'

class ForgotPasswordService {

  static async sendCode(request) {
    return await Axios.post(baseUrl + 'send-code', request);
  }

  static async confirm(request) {
    return await Axios.post(baseUrl + 'confirm', request);
  }

  static async validate(key, clientId, redirectUri) {
    const url = UriHelper.buildUri(`${baseUrl}validate`, { key, clientId, redirectUri });
    return await Axios.get(url);
  }
}

export default ForgotPasswordService;