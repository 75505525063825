class PhoneFormatter {

  static addCountryCode(code, number) {
    return `+${code}` + this.removeMask(number);
  }

  static mask(number) {
    const unmasked = this.removeMask(number);
    if (!unmasked) {
      return '';
    }

    const isMobile = unmasked.length === 14;
    const areaCode = unmasked.slice(3, 5);
    const firstPart = unmasked.slice(5, 9 + isMobile);
    const lastPart = unmasked.slice(9 + isMobile, 13 + isMobile);
    return `(${areaCode}) ${firstPart}-${lastPart}`;
  }

  static removeMask(number) {
    return number
      ? number.replace(/[^+0-9]/g, '')
      : '';
  }

}

export default PhoneFormatter;