import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import Login from './Screens/Login';
import Logout from './Screens/Logout';
import ForgotPassword from './Screens/ForgotPassword';
import ConfirmPassword from './Screens/ConfirmPassword';
import Signup from './Screens/Signup';
import Error from './Screens/Error';
import Profile from './Screens/Profile';
import ForgotEmail from './Screens/ForgotEmail';
import Terms from './Screens/Terms';
import LandingPageUnisul from './Screens/LandingPageUnisul';
import LandingPageHoraDigital from './Screens/LandingPageHoraDigital';
import LandingPageEventoHoje from './Screens/LandingPageEventoHoje';
import PrivacyPolicy from './Screens/PrivacyPolicy';
import Help from './Screens/Help';
import ConfirmSignup from './Screens/ConfirmSignup'
import ChangeMail from './Screens/ChangeMail'
import SignUpCheck from './Screens/SignUpCheck'
import Layout from './Layout';
import Steps from './Util/Steps';

class AppRouter extends Component {

  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={Layout(Login, Steps.identification)} />
          <Route path="/login" component={Layout(Login, Steps.identification)} /> 
          <Route path="/logout" component={Layout(Logout)} />
          <Route path="/confirm-password" component={Layout(ConfirmPassword)} />
          <Route path="/forgot-email" component={Layout(ForgotEmail)} />
          <Route path="/forgot-password" component={Layout(ForgotPassword)} />
          <Route path="/signup" component={Layout(Signup, Steps.signup)} />
          <Route path="/confirm-signup" component={Layout(ConfirmSignup, Steps.emailValidation)} />
          <Route path="/confirm-signup-unisul" component={Layout(ConfirmSignup)} />
          <Route path="/confirm-signup-promotion" component={Layout(ConfirmSignup)} />
          <Route path="/confirm-signup-event" component={Layout(ConfirmSignup)} />
          <Route path="/change-mail" component={Layout(ChangeMail)} />
          <Route path="/confirm-account" component={Layout(SignUpCheck, Steps.validatedEmail)} />
          <Route path="/confirm-account-unisul" component={Layout(SignUpCheck)} />
          <Route path="/profile" component={Layout(Profile)} />
          <Route path="/error" component={Layout(Error)} />
          <Route path="/terms" component={Layout(Terms)} />
          <Route path="/unisul" component={LandingPageUnisul} />
          <Route path="/hora-digital" component={LandingPageHoraDigital} />
          <Route path="/rd-hostel" component={LandingPageEventoHoje} />
          <Route path="/help" component={Layout(Help)} />
          <Route path="/privacy-policy" component={Layout(PrivacyPolicy)} />
          <Route render={() => <Redirect to='/error?code=404' />} />
        </Switch>
      </Router>
    );
  }
}

export default AppRouter;
