import React from 'react';
import './SocialLogin.css';

class SocialLogin extends React.Component {

  render() {
    return (
      <div className="social-login" style={this.props.style}>
        <div className="buttons">
          <button className="button-logo button-logo-generic" onClick={() => window.location = decodeURIComponent(this.props.facebookUrl)}>
            <img className="img-facebook" alt="Facebook" src="/img/facebook_logo.png" />
            <span className="span-generic span-facebook">Iniciar sessão com o Facebook</span>
          </button>
          <button className="button-logo button-logo-generic" onClick={() => window.location = decodeURIComponent(this.props.googleUrl)}>
            <img className="img-google" alt="Google" src="/img/google_logo.png" />
            <span className="span-generic span-google">Iniciar sessão com o Google</span>
          </button>
          <button className="button-logo button-logo-generic button-logo-apple" onClick={() => window.location = decodeURIComponent(this.props.appleUrl)}>
            <img className="img-apple" alt="Apple" src="/img/apple_logo.png" />
            <span className="span-generic span-apple">Iniciar sessão com a Apple</span>
          </button>
        </div>
      </div>
    );
  }

}

export default SocialLogin;
