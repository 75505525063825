
import React from 'react';
import './Separator.css';

class Separator extends React.Component {

  render() {
    return (
      <div className="separator">
        <hr />
        {!!this.props.text && <span className="text">{this.props.text}</span>}
        {!!this.props.text && <hr />}
      </div>
    );
  }

}

export default Separator;
