
import React from 'react';
import './Loader.css';

class Loader extends React.Component {

  render() {
    return (
      <div className="loader" hidden={this.props.loaded}>
        <span className="spinner"> </span>
      </div>
    );
  }

}

export default Loader;