
import React from 'react';
import ReactHtmlParser from 'react-html-parser'; 
import './FormError.css';

class FormError extends React.Component {

  render() {
    return (
      <div className="form-error" hidden={!this.props.message}>{ReactHtmlParser(this.props.message)}</div>
    );
  }

}

export default FormError;