import React, { Component } from 'react';
import Helmet from 'react-helmet'
import './TextPage.css';

class TextPage extends Component {
  render() {
    return (
      <main className="text-page">
      <Helmet>
        <title>{this.props.title} - NSC Comunicação</title>
      </Helmet>
      <div className="title row">
        <h1 className="col-8">{this.props.title}</h1>
        <div className="logo col-4">
          <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 118 101" xmlSpace="preserve">
            <g>
              <path className="st0" d="M111.3,51.9c0,12.4,0,24.7,0,37.1c0,5.2-2.9,7.3-7.8,5.7C73.4,84.8,43.3,75,13.1,65.2
                c-4.5-1.5-5.3-2.6-5.3-7.3c0-10.7,0-21.4,0-32c0-3.9,1.5-5.6,5.4-6.1C26.6,18,40,16.4,53.4,14.7c12.7-1.6,25.5-3.3,38.2-4.9
                c4.5-0.6,8.9-1.2,13.4-1.7c4-0.5,6.4,1.6,6.4,5.6C111.3,26.4,111.3,39.1,111.3,51.9z M21,42.6c0,2.6,0,5.2,0,7.8
                c0,5.4,0,5.3,5.4,5.2c1.9,0,2.7-0.8,2.6-2.7c-0.1-3,0-6,0-9.1c0-1.8,0.3-3.5,1.1-5.1c1.1-2,3.5-3,5.8-2.5c1.8,0.5,2.8,1.9,2.9,4.4
                c0,3.2,0,6.3,0,9.5c0,5.5,0.2,5.7,5.7,5.4c1.7-0.1,2.6-0.8,2.6-2.6c0-2.5,0-5.1,0-7.6c0-3.2,0.2-6.5-0.8-9.6
                c-1.2-3.8-4.2-6.1-8.2-6.4C35,29.3,32.2,30,29.8,32c-0.4,0.3-1.2,1.2-1.5,0.2c-0.6-2.8-2.7-2.2-4.5-2.2c-2.6,0-2.8,0.2-2.8,2.7
                C21,36,21,39.3,21,42.6z M62.1,56c1.8,0.1,3.6-0.2,5.4-0.8c3.7-1.2,5.4-3.7,5.4-7.6c0-3.8-1.7-6-5.6-7.1c-2-0.6-4-0.9-5.9-1.4
                c-0.9-0.2-1.6-0.7-1.5-1.7c0.1-1,0.9-1.4,1.8-1.5c2-0.2,4.1-0.2,6,0.8c2,1,3,0,3.9-1.6c1-1.7,0.1-2.7-1.3-3.4
                c-4.6-2.6-9.4-2.8-14.1-0.4c-3.2,1.6-4.2,4.3-3.9,7.7c0.2,3.1,2.1,4.7,4.8,5.8c2,0.8,4.1,0.8,6.2,1.4c1,0.3,2.2,0.6,2.1,2
                c-0.1,1.5-1.3,1.7-2.5,1.8c-2.4,0.2-4.8-0.1-7-1.1c-1.9-0.8-2.7-0.4-3.9,1.9C51,52.1,51.7,53,53,53.8C55.8,55.4,58.9,56,62.1,56z
                M77.1,43.1c-0.1,1.4,0.2,3.2,0.8,4.9c1.7,4.5,4.9,7.2,9.6,7.9c4.5,0.6,8.9,0.2,12.1-3.7c0.8-0.9,1.7-2,0.5-3.3
                c-1.8-2-3.2-2.3-4.8-1.1c-1.6,1.2-3.3,1.8-5.3,1.4c-2.6-0.5-4.3-2.3-4.7-5c-0.4-2.7-0.2-5.3,2.4-7c2.4-1.5,4.9-1.4,7.3,0.2
                c1.6,1,3.4,0.5,4.8-1.2c1.1-1.5,0.1-2.5-0.8-3.5c-0.4-0.4-0.8-0.8-1.3-1.1c-4.3-2.9-9-3-13.4-0.9C79.4,33,77,37.1,77.1,43.1z"/>
            </g>
          </svg>
        </div>
      </div>
      <div className="text">
        {this.props.children}
        <img className="logos" src="/img/logos_nsc_products.png" alt="NSC Total" />
      </div>
    </main>
    );
  }
}

export default TextPage;

