import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import RegistrationService from '../Service/RegistrationService';
import Validation from '../Util/Validation';
import ErrorMessages from '../Util/ErrorMessages';
import UriHelper from '../Util/UriHelper';
import Dialog from '../Components/Dialog';
import * as yup from 'yup';
import './LandingPageUnisul.css';
import ProfileService from '../Service/ProfileService';
import FormError from '../Components/FormError';
import Box from '../Components/Box';
import { Link } from 'react-router-dom';
import Checkbox from '../Components/Checkbox';
import MaskedInput from 'react-text-mask';

class LandingPageUnisul extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      facebookUrl: '',
      googleUrl: '',
      appleUrl: '',
      openDialog: false,
      params: {},
      error: null,
      userExists: false,
      registration: ''
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.renderFormSignup = this.renderFormSignup.bind(this);
    this.onFormSignupSubmit = this.onFormSignupSubmit.bind(this);
    this.onModalCloseHandle = this.onModalCloseHandle.bind(this);
  }

  onModalCloseHandle() {
    this.setState({
      openDialog: false
    });
  }

  async componentDidMount() {
    const params = UriHelper.getParams(this.props.location.search);
    params.clientId = "4jig7enkebofiippp70f2lfk56";
    this.setState({
      params: params
    });
  }

  render() {
    return (
      <div>
        <div className="bg-unisul">
          <div className="container">
            <section className="section section-one">
              <img className="img-1" src="/img/lp-banner-1.png" alt="" />
              <div className="form-box">
                <Formik
                  onSubmit={this.onFormSubmit}
                  render={this.renderForm}
                  initialValues={{
                    registration: this.state.registration,
                  }}
                  validationSchema={this.getFormValidationSchema} />
              </div>
            </section>
          </div>
        </div>
        <div className="container">
          <section className="section section-two row">
            <div className="collumn">
              <div className="img-2"></div>
              <div style={{ margin: 'auto' }}>
                <img className="logos" src="/img/logos_nsc_products.png" alt="NSC Total" />
              </div>
            </div>
            <div className="collumn">
              <div className="section-two-description">
                <h1 className="title">MUITOS BENEFÍCIOS PARA VOCÊ APROVEITAR:</h1>
                <ul className="list">
                  <li>Acesso ilimitado ao conteúdo digital dos jornais DC, AN e SANTA;</li>
                  <li>Conteúdo das rádios CBN Diário, CBN Joinville e Itapema;</li>
                  <li>Análises e opiniões do nosso time de colunistas;</li>
                  <li>Versão digital dos jornais impressos da NSC;</li>
                  <li>Aplicativo NSC Total com conteúdo personalizado;</li>
                  <li>Você se torna sócio do Clube NSC, o maior clube de vantagens de SC;</li>
                  <li>Tenha desconto em mais de 500 parceiros em Santa Catarina e no Brasil;</li>
                  <li>Economize em compras online, refeições, delivery, cinema, entre outros.</li>
                </ul>
              </div>
            </div>
          </section>
        </div>

        <Dialog isOpen={this.state.openDialog} onClose={this.onModalCloseHandle}>
          <Box className="signup"
            pageTitle="Criar conta"
            title="Crie sua conta"
            logo={true}
            showImage={true}
            showBackButton={false}
            description={'Preencha os dados abaixo'}>
            <Formik
              onSubmit={this.onFormSignupSubmit}
              render={this.renderFormSignup}
              initialValues={{
                registration: this.state.registration,
                firstName: '',
                lastName: '',
                email: '',
                document: '',
                password: '',
                mailingList: true,
              }}
              validationSchema={this.getFormSignupValidationSchema} />
          </Box>
        </Dialog>
      </div>
    );
  }

  renderForm({ errors, touched, isSubmitting }) {
    return (
      <div className="container">
        <Form className="form">
          <img src="/img/logo-unisul.png" alt="" />
          <h2>5 MESES GRÁTIS DE NSC Total + Clube NSC</h2>
          <p>Informação de qualidade e vários descontos: o nosso presente para você.</p>
          <div className="form-group">
            <Field
              placeholder="Coloque aqui o seu código de acesso"
              type="text"
              name="registration"
              className={errors.registration && touched.registration ? 'error' : ''} />
            <ErrorMessage name="registration" component="div" className="error-message" />
          </div>
          <FormError message={this.state.error} />
          <button className="btn-submit" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Aguarde' : 'Acessar gratuitamente'}
          </button>
        </Form>
      </div>
    );
  }

  renderFormSignup({ errors, touched, isSubmitting }) {
    return (
      <Form className="form">
        <div className="form-group">
          <label>Matrícula</label>
          <ErrorMessage name="registration" component="div" className="error-message" />
          <Field
            type="text"
            name="registration"
            className={errors.registration && touched.registration ? 'error' : ''}
            disabled />
        </div>
        <div className="form-group">
          <label>Nome*</label>
          <ErrorMessage name="firstName" component="div" className="error-message" />
          <Field
            type="text"
            name="firstName"
            placeholder="Digite seu nome"
            className={errors.firstName && touched.firstName ? 'error' : ''} />
        </div>
        <div className="form-group">
          <label>Sobrenome*</label>
          <ErrorMessage name="lastName" component="div" className="error-message" />
          <Field
            type="text"
            name="lastName"
            placeholder="Digite seu sobrenome"
            className={errors.lastName && touched.lastName ? 'error' : ''} />
        </div>
        <div className="form-group">
          <label>CPF*</label>
          <ErrorMessage name="document" component="div" className="error-message" />
          <Field
            type="text"
            name="document"
            render={({ field }) => (
              <MaskedInput
                {...field}
                type="text"
                placeholder="Digite seu CPF"
                className={errors.document && touched.document ? 'error' : ''}
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
              />
            )} />
        </div>
        <div className="form-group">
          <label>E-mail*</label>
          <ErrorMessage name="email" component="div" className="error-message" />
          <Field
            type="text"
            name="email"
            autoCorrect="off"
            autoCapitalize="off"
            placeholder="Digite seu e-mail"
            className={errors.email && touched.email ? 'error text-lower-case' : 'text-lower-case'} />
        </div>
        <div className="form-group">
          <label>Senha*</label>
          <ErrorMessage name="password" component="div" className="error-message" />
          <Field
            type="password"
            name="password"
            placeholder="Digite sua senha"
            className={errors.password && touched.password ? 'error' : ''} />
        </div>
        <div className="terms-message">
          <span>Efetuando o cadastro você estará concordando com os </span>
          <Link className="link" to="/terms" target="_blank">Termos de Uso</Link>
          <span> e a </span>
          <Link className="link" to="/privacy-policy" target="_blank">Política de Privacidade/Uso de Cookies</Link>
        </div>
        <Field component={Checkbox}
          name="mailingList"
          size="large"
          label="Desejo receber novidades e promoções por e-mail." />
        <div className="account-confirmation-text">Será enviado um e-mail para confirmação do seu cadastro.</div>
        <div hidden={!this.state.userExists} className="user-exists">
          <span>Já existe um usuário com o e-mail informado, faça seu login </span>
          <Link className="link" to={UriHelper.loginLink(this.state.params)}> aqui </Link>
        </div>
        <FormError message={this.state.error} />
        <button className="btn-submit" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Aguarde' : 'Cadastrar'}
        </button>
      </Form>
    );
  }

  getFormSignupValidationSchema() {
    const params = UriHelper.getParams(window.location.search);
    return yup.object().shape({
      registration: Validation.registration({ required: true }),
      firstName: Validation.properName({ required: true }),
      lastName: Validation.properName({ required: true }),
      email: Validation.email({ required: true }),
      password: Validation.password({ required: true }),
      document: Validation.document({ required: !!params.fullSignup }),
    });
  }

  async onFormSignupSubmit(values, { setSubmitting }) {
    try {
      const { data } = await RegistrationService.signUpUnisul({
        clientId: this.state.params.clientId,
        redirectUri: this.state.params.redirectUri,
        state: this.state.params.state,
        code: values.registration,
        name: values.firstName,
        lastName: values.lastName,
        email: values.email.toLowerCase(),
        password: values.password,
        document: values.document.replaceAll(/[.-]/g, ''),
        mailingLists: values.mailingList ? 'NSC;' : '',
        terms: 'LGPD;'
      });

      const queryParams = this.state.params.responseType === 'code'
        ? { ...this.state.params }
        : { accessToken: data.accessToken, idToken: data.idToken, ...this.state.params };

      switch (data.status) {
        case 'SUCCESS_EMAIL_SENT':
          ProfileService.storeData({ statusSign: true, email: values.email.toLowerCase(), user: data.userSub });
          const url = UriHelper.confirmSignupUnisul(queryParams);
          this.props.history.push(url);
          break;

        case 'SUCCESS_ADD_CLUB':
        case 'SUCCESS_ADD_DC_CLUB':
          window.location.href = this.state.params.redirectUri;
          break;

        case 'USERNAME_EXISTS':
          this.setState({ error: null, userExists: true });
          break;

        default:
          this.setState({ error: ErrorMessages.getDescription(data.status), userExists: false });
          break;
      }
    } catch (error) {
      const errorDescription = ErrorMessages.getDescription(error.response.data.status);
      this.setState({
        error: errorDescription,
      });
    }

    setSubmitting(false);
  }

  getFormValidationSchema() {
    return yup.object().shape({
      registration: Validation.registration({ required: true })
    });
  }

  async onFormSubmit(values, { setSubmitting }) {
    try {
      this.setState({ error: null });
      const { data } = await RegistrationService.verifyRegistration(values.registration);
      if (data.status === 'VALID') {
        this.setState({
          registration: values.registration,
          openDialog: true
        });
      } else {
        this.setState({
          error: ErrorMessages.getDescription(data.status),
        });
      }
    } catch (error) {
      const errorDescription = ErrorMessages.getDescription(error.response.data.status);
      this.setState({
        error: errorDescription,
      });
    }
    setSubmitting(false);
  }

}

export default LandingPageUnisul;
