import React from 'react';
import { Formik, Form } from 'formik';
import FadeLoader from 'react-spinners/FadeLoader'
import './SignUpCheck.css';
import ErrorMessages from '../Util/ErrorMessages';
import UriHelper from '../Util/UriHelper';
import Box from '../Components/Box';
import ProfileService from '../Service/ProfileService';
import Logos from '../Components/Logos';

/* eslint-disable  jsx-a11y/anchor-is-valid */
class SignUpCheck extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      params: {},
      title: 'Verificando seu e-mail',
      description: '',
      verifying: true,
      confirmed: false,
      error: '',
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.resendConfirmation = this.resendConfirmation.bind(this);
  }

  async componentDidMount() {
    const params = UriHelper.getParams(this.props.location.search);
    this.setState({
      error: '',
      params,
      username: params.username,
      email: params.email
    });

    await this.handleAccountCofirmation(params);
  }

  render() {
    return (
      <Box
        className="sign-up-check"
        pageTitle={this.state.title}
        message={this.state.message}
        description={this.state.description}
        title={this.state.title}>
        <Formik
          onSubmit={this.onFormSubmit}
          render={this.renderForm} />

        <div style={{ marginTop: '30px' }}>
          <Logos />
        </div>

      </Box>
    );
  }

  renderForm({ isSubmitting }) {
    return (
      <Form className="form">
        <FadeLoader
          size={10}
          color={"#ee6b25"}
          css={{ margin: '0 auto' }}
          loading={this.state.verifying}
        />
        <button className="btn-submit" type="submit" hidden={this.state.verifying || !this.state.confirmed} disabled={isSubmitting}>
          {isSubmitting ? 'Aguarde' : 'Continuar'}
        </button>
        <button className="btn-submit" type="button" onClick={this.resendConfirmation} hidden={this.state.verifying || !this.state.confirmFailed} disabled={isSubmitting}>
          {isSubmitting ? 'Aguarde' : 'Reenviar e-mail'}
        </button>
      </Form>
    );
  }

  async onFormSubmit({ setSubmitting }) {
    this.redirect();
    setSubmitting(false);
  }

  async handleAccountCofirmation(params) {
    const { data } = await ProfileService.confirmAccount({
      ...params
    });

    if (data.status === 'SUCCESS') {
      this.setState({
        title: "E-mail confirmado com sucesso",
        description: "",
        verifying: false,
        confirmed: true,
        code: data.code
      });
    } else {
      this.setState({
        title: "Sua validação expirou",
        description: "Clique no botão abaixo para enviar um novo e-mail",
        verifying: false,
        confirmFailed: true
      });
    }
  }

  redirect() {
    const queryParams = { code: this.state.code, state: this.state.params.state };
    window.location.href = UriHelper.buildUri(this.state.params.redirectUri, queryParams);
  }

  async resendConfirmation() {
    const { data } = await ProfileService.sendSignUpCode({
      user: this.state.username,
      clientId: this.state.params.clientId,
      redirectUri: this.state.params.redirectUri,
      state: this.state.params.state
    });

    if (data.status === 'SUCCESS') {
      const uri = UriHelper.confirmSignup({ ...this.state.params });
      this.props.history.push(uri);
    } else {
      this.setState({
        message: {
          status: data.status,
          message: ErrorMessages.getDescription(data.status)
        }
      });

    }
  }
}

export default SignUpCheck;
