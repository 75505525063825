import React from 'react';
import './Terms.css';
import TextPage from '../Components/TextPage';

class Terms extends React.Component {

  render() {
    return (
      <TextPage title="Termos de Uso">
        <p>Este instrumento, bem como outros termos, contratos, políticas, regras, exigências, limitações, sinalizações, notas, notificações e demais condições disponíveis no Portal NSCTOTAL.com.br, inclusive termos de uso de produtos específicos e a Política de Segurança e Privacidade do Portal NSCTOTAL.com.br localizada no endereço http://www.usuarionsc.com.br/privacidade.html (“Política de Privacidade”), documentos estes passíveis de alteração e tal como vigentes à época de utilização do Portal NSCTOTAL.com.br (doravante, todos, em conjunto, denominados "Termos Gerais"), regulamentam a disponibilização e a utilização e acesso pelo usuário (doravante simplesmente denominado “Você”) aos Conteúdos (conforme definição do termo abaixo), bem como limitam a responsabilidade da NC COMUNICAÇÕES S/A, pessoa jurídica de direito privado, com sede na Avenida Governador Ivo Silveira, n.º 3.811, cidade de Florianópolis, Estado de Santa Catarina, inscrita no C.N.P.J./M.F. sob o n.º 79.227.963/0002-63, doravante referida apenas como "NSC Comunicação", pela disponibilização dos mesmos.</p>
        <p>Esta política permitirá que você saiba:</p>
        <p>1. Direitos sobre o conteúdo disponibilizado;</p>
        <p>2. Isenção de responsabilidade;</p>
        <p>3. Segurança e fluxo normal de comunicações entre servidores;</p>
        <p>4. Aceitação;</p>
        <p>5. Suspensão, bloqueio e exclusão;</p>
        <p>6. Condições gerais.</p>
        <h2>1. Direitos sobre o conteúdo disponibilizado</h2>
        <p>Todos os materiais contidos no Portal NSCTOTAL.com.br e nos aplicativos disponibilizados pela NSC Comunicação, incluindo mas sem se limitar a textos, vídeos, formatos, ilustrações, animações, gráficos, fotografias, marcas, patentes, fontes, grafismo, esquemas de cores, layout, trade-dress, identidade visual, experiência de navegação, designs, aplicativos, interfaces de aplicativos, softwares, fragmentos de softwares (doravante designados coletivamente ou em relação a qualquer parcela destes como “Conteúdo”) são protegidos pela legislação brasileira, em especial pela Lei de Direitos Autorais (Lei Federal 9.610/98) e Lei de Propriedade Industrial (Lei Federal 9.279/96).</p>
        <p>Em relação ao uso autorizado dos Conteúdos, este consiste unicamente em uma licença a Você, não exclusiva, intransferível, revogável e limitada, estritamente para visualização do Conteúdo nos meios disponibilizados e autorizados pela NSC Comunicação, sempre em âmbito pessoal e privado, sem fins comerciais. Nenhum outro direito adicional é concedido a Você salvo se feito de forma expressa e por escrito pela NSC Comunicação ou legítimo titular de direitos sobre o Conteúdo.</p>
        <p>Lembramos que qualquer acesso e/ou utilização que não os expressamente autorizados ficam vedados. São vedadas, exemplificativamente, as seguintes práticas, seja por meio de ação, dispositivo, mecanismo, software ou de qualquer forma e para qualquer fim:</p>
        <p>(i) remover, alterar, interferir, degradar, desativar, evitar ou de qualquer forma modificar marca d’água, copyright, símbolo, marca ou qualquer outro sinal indicativo de titularidade ou para outro fim inserido no Conteúdo, ou quaisquer direitos e/ou mecanismos de proteção associados ao Conteúdo, bem como filtros e controle de acesso, inclusive se baseados em território (geoblocking) ou faixa etária;</p>
        <p>(ii) copiar no todo ou em parte, fazer download, executar engenharia reversa, “descompilar”, “recompilar”, “compilar”, criptografar, “desencriptografar”, desmembrar, capturar, reproduzir, arquivar, distribuir, fazer upload, publicar, modificar, traduzir, exibir, transmitir, distribuir, disponibilizar, utilizar métodos de data mining, coleta ou extração de dados, ou embeddar o Conteúdo;</p>
        <p>(iii) utilizar meta-tags para extração dos Conteúdos, reorganizar, armazenar, captar, exibir, retransmitir, tornar disponível ou incorporar o Conteúdo, inclusive frames, recaps e screenshots em qualquer meio;</p>
        <p>(iv) comercializar, distribuir gratuita ou onerosamente, anunciar ou desenvolver um negócio, direta ou indiretamente, a partir do Conteúdo;</p>
        <p>(v) utilizar o Conteúdo de qualquer forma para a criação de obras derivadas ou nele baseadas, tais como aplicativos, websites, montagens, mash-ups, vídeos e materiais de marketing ou merchandising, entre outros;</p>
        <p>(vi) modificar, remover, interferir, tentar alterar qualquer parte do Conteúdo, em especial os players de vídeo, ou qualquer tecnologia por trás dos mesmos. Essa restrição também se aplica a qualquer alteração, interferência ou ação que de qualquer forma possibilite, ainda que de forma privada, a visualização do Conteúdo sem que sejam exibidos e/ou utilizados os aplicativos e players de vídeo disponibilizados pela NSC Comunicação e todos os elementos relacionados ao Portal NSCTOTAL.com.br (tais como interface, marcas registradas e eventuais anúncios) ou sem que sejam acessíveis todas as funcionalidades, anúncios e interfaces associados aos Conteúdos pela NSC Comunicação;</p>
        <p>(vii) associar ou gerar qualquer impressão, de fato ou em potência, de associação de nomes, produtos, pessoas, empresas, terceiros ou de qualquer elemento com os Conteúdos e/ou com a NSC, seus sócios, talentos, afiliadas ou dirigentes; ou</p>
        <p>(viii) utilizar de qualquer interface (API) da própria NSC ou de terceiros para qualquer fim, inclusive para extração, distribuição, execução e/ou disponibilização dos Conteúdos.</p>
        <p>Excepcionalmente, a NSC Comunicação autoriza a indexação automatizada de textos presentes no Conteúdo, desde que estejam disponíveis em áreas de acesso irrestrito do Portal NSCtotal.com.br e não contenham qualquer ressalva à indexação, por crawlers de ferramentas de buscas de utilização pública e gratuita exclusivamente para apresentação de hyperlinks em resultados gerais de busca que apontem para os Conteúdos, desde que isto não importe em violação dos itens “i” a “viii” acima, em especial do item “iii”.</p>
        <h2>2. Isenção de responsabilidade</h2>
        <p>Hyperlinks externos não constituem endosso pela NSC Comunicação dos sites/ambientes vinculados ou as informações, produtos ou serviços ali contidos.</p>
        <p>A NSC Comunicação não concede qualquer garantia relacionada à disponibilidade, continuidade de funcionamento ou infalibilidade do Portal NSCTOTAL.com.br e/ou Conteúdos, nem que os mesmos serão úteis para a realização de qualquer atividade em particular. Ainda, consideradas as características do meio internet, a NSC Comunicação não garante a segurança e privacidade do Conteúdo fora do domínio nsctotal.com.br, salvo se nos termos da Política de Privacidade, nem que o mesmo será ininterrupto, livre de vírus ou outros problemas, erros e ataques e, em particular, não garante que terceiros não autorizados não possam acessar e, eventualmente, interceptar, eliminar, alterar, modificar ou manipular de qualquer modo os dados presentes e/ou transmitidos a seus servidores.</p>
        <h2>3. Segurança e fluxo normal de comunicações entre servidores</h2>
        <p>Fica expressamente vedada qualquer prática que possa prejudicar a imagem da NSC Comunicação e NSC total ou violar direitos desta sobre o Conteúdo, danificar seu patrimônio, danificar ou de qualquer forma interferir no fluxo normal de comunicações com seus servidores, na segurança, inviolabilidade e privacidade dos dados lá armazenados e transmitidos, incluindo, mas não se limitando àquelas que importem, possam importar em ou favoreçam:</p>
        <p>(i) Interceptação ou monitoramento do tráfego de dados a partir ou para os servidores da NSC Comunicação;</p>
        <p>(ii) Fraudar, mascarar ou alterar a origem de comunicações (incluindo, mas sem se limitar a alteração de cabeçalhos de e-mails, origem de arquivos, origem da requisição de dados, etc.);</p>
        <p>(iii) Degradar, fazer qualquer uso que não expressamente autorizado ou sobrecarregar com qualquer finalidade os servidores da NSC Comunicação;</p>
        <p>(iv) Acessar qualquer dado disponibilizado nos servidores da NSC Comunicação de forma ilegítima (falsificando credenciais, utilizando-se de credenciais de terceiros, etc.); ou</p>
        <p>(v) Quebrar, danificar, adulterar ou degradar qualquer parte ou mecanismo de segurança do Portal NSCTOTAL.com.br e seus servidores.</p>
        <h2>4. Aceitação</h2>
        <p>Ao assistir e/ou utilizar o Conteúdo de qualquer forma, Você expressa sua aceitação plena e sem reservas aos Termos Gerais tal como vigentes naquele instante, para todos os fins de direito. Assim, a leitura atenta por Você dos Termos Gerais em cada uma das ocasiões em que se propuser a assistir e/ou utilizar o Conteúdo é recomendável. Se Você não concordar com algum dos termos e regras aqui previstos, Você não deverá assistir e/ou utilizar o Conteúdo.</p>
        <h2>5. Suspensão, bloqueio e exclusão</h2>
        <p>A fim de otimizar a disponibilização do Conteúdo, a NSC Comunicação poderá, inclusive por questões de segurança, restringir, limitar e realizar a suspensão ou bloqueios, totais ou parciais, de disponibilização e acesso ao Conteúdo.</p>
        <p>A NSC Comunicação poderá descontinuar a disponibilidade ou distribuição de qualquer Conteúdo, a qualquer tempo e sem necessidade de prévio aviso.</p>
        <h2>6. Condições gerais</h2>
        <p>No caso de conflitos existentes entre regras e instrumentos listados na definição dos Termos Gerais constantes no caput deste instrumento, deverão prevalecer regras e condições sobre produtos e/ou serviços específicos tal como disponibilizados por escrito pela NSC Comunicação.</p>
        <p>O não exercício pela NSC Comunicação de quaisquer direitos ou faculdades que lhe sejam conferidos por estes Termos Gerais ou pela legislação brasileira, bem como a eventual tolerância contra infrações a estes Termos Gerais ou a seus direitos, não importará na renúncia aos seus direitos, renovação ou alteração de itens e cláusulas deste instrumento, podendo ainda a NSC Comunicação exercer seus direitos a qualquer momento.</p>
        <p>Estes Termos Gerais, bem como a relação decorrente das ações aqui compreendidas, assim como qualquer disputa que surja em virtude disto será regulada exclusivamente pela legislação brasileira.</p>
        <p>Fica eleito o Foro Central da Comarca da Capital do Estado de Santa Catarina para dirimir quaisquer questões oriundas da utilização dos Conteúdos e/ou violação destes Termos Gerais, renunciando as partes a qualquer outro, por mais privilegiado que seja ou venha a ser.</p>
        <p>Caso você considere que existem fatos, circunstâncias ou suspeita de qualquer uso inadequado dos Conteúdos ou qualquer questão de segurança relacionada ao NSCTOTAL.com.br, e, em particular, que representem violação de direito autoral ou de propriedade intelectual ou outros direitos, pedimos que envie uma comunicação à NSC Comunicação por meio do e-mail <a href="mailto:atendimento.nsc@nsc.com.br">atendimento.nsc@nsc.com.br</a> contendo, se possível, seus dados pessoais (nome, endereço e número de telefone), bem como especificação do evento verificado por Você (em particular, quando se tratar de suposta violação de direitos autorais, indicação precisa e completa dos conteúdos protegidos e supostamente infringidos com as respectivas URLs).</p>
        <p>NSCTOTAL.com.br© Copyright 2019 NC Comunicações</p>
      </TextPage>
    );
  }
}

export default Terms;