import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import './ConfirmPassword.css';
import PasswordRecoveryService from '../Service/PasswordRecoveryService';
import FormError from '../Components/FormError';
import Validation from '../Util/Validation';
import ErrorMessages from '../Util/ErrorMessages';
import UriHelper from '../Util/UriHelper';
import Box from '../Components/Box';

class ConfirmPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      params: {},
      username: '',
      error: '',
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  async componentDidMount() {
    const params = UriHelper.getParams(this.props.location.search);
    this.setState({
      error: '',
      params,
      username: params.email || '',
    });

    try {
      if (params.requestKey) {
        const result = await PasswordRecoveryService.validate(params.requestKey, params.clientId, params.redirectUri);

        if (result.data.valid) {
          this.setState({
            username: result.data.username,
          });
        }
      }
    } catch (error) {
      const uri = UriHelper.error({ code: error.response.status });
      this.props.history.push(uri);
    }
  }

  render() {
    return (
      <Box pageTitle="Cadastro de nova senha"
        title="Cadastrar nova senha NSC"
        description="Informe o código de verificação enviado por e-mail">
        <Formik
          onSubmit={this.onFormSubmit}
          render={this.renderForm}
          initialValues={{
            username: this.state.username,
            code: this.state.params.code || '',
            password: ''
          }}
          validationSchema={this.getFormValidationSchema}
          enableReinitialize={true} />
      </Box>
    );
  }

  renderForm({ errors, touched, isSubmitting }) {
    return (
      <Form className="form">
        <div className="form-group">
          <label>E-mail</label>
          <ErrorMessage name="username" component="div" className="error-message" />
          <Field
            type="text"
            name="username"
            disabled={this.state.username}
            placeholder="Digite seu e-mail"
            className={errors.username && touched.username ? 'error' : ''} />
        </div>
        <div className="form-group">
          <label>Código de verificação</label>
          <ErrorMessage name="code" component="div" className="error-message" />
          <Field
            type="text"
            name="code"
            disabled={this.state.params.code}
            placeholder="Digite o código de verificação"
            className={errors.code && touched.code ? 'error' : ''} />
        </div>
        <div className="form-group">
          <label>Nova senha</label>
          <ErrorMessage name="password" component="div" className="error-message" />
          <Field
            type="password"
            name="password"
            placeholder="Digite sua nova senha"
            className={errors.password && touched.password ? 'error' : ''} />
        </div>
        <FormError message={this.state.error} />
        <button className="btn-submit" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Aguarde' : 'Cadastrar'}
        </button>
      </Form>
    );
  }

  getFormValidationSchema() {
    return yup.object().shape({
      username: Validation.email({ required: true }),
      code: Validation.veficationCode({ required: true }),
      password: Validation.password({ required: true }),
    });
  }

  async onFormSubmit(values, { setSubmitting }) {
    try {
      await PasswordRecoveryService.confirm({
        username: this.state.username || values.username,
        code: this.state.params.code || values.code,
        password: values.password,
        clientId: this.state.params.clientId,
        redirectUri: this.state.params.redirectUri,
      });

      const url = UriHelper.login({
        ...this.state.params,
        email: this.state.username || values.username,
      });

      this.props.history.push(url);
    } catch (error) {
      this.setState({
        error: ErrorMessages.getDescription(error.response.data.status),
      });
    }

    setSubmitting(false);
  }
}

export default ConfirmPassword;