import React from 'react';
import './Logos.css';

class Logos extends React.Component {

  render() {
    return (
      <div className="logos-wrapper"><img className="logos" src="/img/logos_nsc_products.png" alt="NSC Total" /></div>
    );
  }

}

export default Logos;
