import React, { Component } from "react";
import Header from './Components/Header';
import Footer from './Components/Footer';

const Layout = (WrappedComponent, step = false) =>
  class Layout extends Component {

    render() {
      return (
        <div className="app">
          <Header step={step} />
          <WrappedComponent {...this.props} />
          <Footer />
        </div>
      );
    }
  };

export default Layout;
