import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import './ChangeMail.css';
import ErrorMessages from '../Util/ErrorMessages';
import Validation from '../Util/Validation';
import UriHelper from '../Util/UriHelper';
import Box from '../Components/Box';
import ProfileService from '../Service/ProfileService';

/* eslint-disable  jsx-a11y/anchor-is-valid */
class ChangeMail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            params: {},
            username: '',
            email: '',
            error: '',
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.renderForm = this.renderForm.bind(this);
        this.cancel = this.cancel.bind(this);
    }

    async componentDidMount() {
        const params = UriHelper.getParams(this.props.location.search);
        const userData = ProfileService.getUserData();
        this.setState({
            error: '',
            params,
            username: userData.user,
            email: userData.email
        });
    }

    render() {
        return (
            <Box
                className="change-mail"
                pageTitle="Alterar o e-mail"
                message={this.state.message}
                title="Alterar o e-mail">
                <Formik
                    onSubmit={this.onFormSubmit}
                    render={this.renderForm}
                    validationSchema={this.getFormValidationSchema} />
            </Box>
        );
    }

    renderForm({ errors, touched, isSubmitting }) {
        return (
            <Form className="form">
                <div className="form-group">
                    <label>E-mail*</label>
                    <ErrorMessage name="email" component="div" className="error-message" />
                    <Field
                        type="text"
                        name="email"
                        autoCorrect="off"
                        autoCapitalize="off"
                        placeholder="Digite seu e-mail"
                        className={errors.email && touched.email ? 'error text-lower-case' : 'text-lower-case'} />
                </div>
                <button className="btn-submit" type="submit" disabled={isSubmitting}>
                    {isSubmitting ? 'Aguarde' : 'Alterar'}
                </button>
                <div className="cancel-container">
                    <a href="#" className="btn-cancel" onClick={this.cancel}>Cancelar</a>
                </div>
            </Form>
        );
    }

    getFormValidationSchema() {
        return yup.object().shape({
            email: Validation.email({ required: true }),
        });
    }

    async onFormSubmit(values, { setSubmitting }) {
        try {
            const { data } = await ProfileService.changeMail({
                user: this.state.username,
                email: values.email.toLowerCase(),
                clientId: this.state.params.clientId,
                redirectUri: this.state.params.redirectUri,
                state: this.state.params.state
            });

            if (data.status === 'SUCCESS') {
                let userData = ProfileService.getUserData();
                userData.email = values.email.toLowerCase();
                ProfileService.storeData(userData);
            }
            const url = UriHelper.confirmSignup({ ...this.state.params, status: data.status });
            this.props.history.push(url);

        } catch (error) {
            this.setState({
                message: ErrorMessages.getDescription(error.response.data.status),
            });
        }

        setSubmitting(false);
    }

    cancel() {
        const url = UriHelper.confirmSignup({ ...this.state.params });
        this.props.history.push(url);
    }
}

export default ChangeMail;
