import React from 'react';
import './PrivacyPolicy.css';
import TextPage from '../Components/TextPage';

class PrivacyPolicy extends React.Component {

  render() {
    return (
      <TextPage title="Privacidade NSC">
        <h1>Política de Privacidade de Dados </h1>
        <p>Esta Política de Privacidade tem por objetivo informar os direitos dos titulares dos dados pessoais e descrever a forma como tratamos os dados pessoais das pessoas físicas que se relacionam com a NSC, NC Comunicações S.A., e todas as empresas coligadas, que integram, ou que atuam sob a marca NSC, adiante denominadas “NSC”.</p>
        <p>A NSC reforça seu compromisso de cumprimento da legislação vigente, informa que esta política pode ser alterada sempre que necessário.</p>
        <h2>1. Quais dados pessoais são coletados e tratados pela NSC? </h2>
            <p>A NSC poderá coletar e tratar diferentes categorias de dados pessoais, como os dados de identificação (nome, CPF, data de nascimento, entre outros), contato (endereço e telefone), serviço, interesses e perfis de utilização. Coletamos os dados que você nos fornece diretamente ao preencher um cadastro e aqueles obtidos indiretamente, quando você navega, conforme explicamos em nossa Política de Cookies.</p>
        <h2>2. Quando a NSC coleta os seus dados pessoais? </h2>
            <p>Os dados pessoais serão coletados quando for feita a adesão ou utilização de um serviço da NSC, quando for assinado um serviço, ou uma publicação, ou ainda newsletters, quando é feito o registro através da criação de uma conta de cliente, quando instalam uma aplicação/app, quando participam de promoções comerciais, concursos culturais, e afins. </p>
        <h2>3. Por que os dados pessoais são coletados e tratados? </h2>
            <p>A NSC faz o tratamento de dados pessoais para as seguintes finalidades: </p>
            <p>a) Execução de diligências pré-contratuais, como nos casos em que o tratamento dos dados pessoais seja necessário para a adesão ou subscrição de um serviço da NSC, ou para possibilitar o envio de newsletters da NSC; </p>
            <p>b) Prestação dos serviços, ou execução de um contrato, incluindo adesão aos serviços (assinaturas e subscrições), gestão de conteúdos, contatos para informações, gestão administrativa, contábil e fiscal, inclusive faturamentos, pedidos e gravação de chamadas para prova de transações comerciais, ou quaisquer outras comunicações que dizem respeito à relação contratual; </p>
            <p>c) Recolhimento de dados para determinar o número de usuários, ou para compreender o modo como os usuários utilizam as plataformas digitais e para identificar o percurso que é feito dentro dos mesmos, com objetivo de melhoria contínua, e para que seja possível proporcionar uma experiência otimizada; </p>
            <p>d) Inserção de publicidade nas plataformas digitais da NSC e análise de perfis de navegação) O desenho de perfis de navegação nas plataformas digitais da NSC tem a função de disponibilizar conteúdos e gerar sugestões personalizadas, de forma a melhor atender o Utilizador; </p>
            <p>e) Análise da utilização das plataformas digitais e serviços NSC, definição de público-alvo na preparação de eventos, ativação de marca, estudos de mercado, também com o objetivo de disponibilizar conteúdos e gerar sugestões personalizadas, de forma a melhor atender o Utilizador; </p>
            <p>f) Por legítimo interesse da NSC ou de terceiros, objetivando o apoio e promoção de atividades do controlador, por exemplo, a melhoria e o desenvolvimento de serviços; </p>
            <p>g) Marketing, incluindo venda ou marketing de serviços, análise de perfis e preferências de utilização dos serviços, melhoria e desenvolvimento dos serviços e da experiência de utilização, oferta de conteúdos, gestão de conteúdos disponibilizados por anunciantes; </p>
            <p>h) Recolhimento de dados no âmbito do atendimento ao espectador e ouvinte, tendo em vista o esclarecimento de dúvidas e questões e o recebimento de sugestões; </p>
            <p>i) Recolhimento de dados para possibilitar o envio de alertas e notificações de serviços subscritos pelos utilizadores; </p>
            <p>j) Cobrança extrajudicial, judicial e pagamentos; </p>
            <p>l) Gestão de respostas técnicas e reclamações; </p>
            <p>m) Consulta de conteúdos audiovisuais e cinematográficos, registro para solicitação de cópias de conteúdo, registro de pedidos de licenciamento de conteúdos e gestão de dados para faturamento; </p>
            <p>n) Recolhimento de dados através do preenchimento de formulários online para submissão de pedidos de inscrição em concursos, promoções comerciais, ou projetos organizados pela NSC; </p>
            <p>o) Registro e gestão de convidados em eventos e programas da NSC; </p>
            <p>p) Contatos com os participantes vencedores dos concursos e projetos para entrega de prêmios. </p>
        <h2>4. Com quem a NSC compartilha os seus dados pessoais? </h2>
            <p>a) Pessoas que prestam seus serviços à NSC (por exemplo, prestadores de serviços de Tecnologia da Informação); </p>
            <p>b) Empresas coligadas, que integram ou que atuam sob a marca NSC; </p>
            <p>c) Entidades parceiras, nos casos em que tiver prestado o seu consentimento para esse efeito, ou que tal for necessário à prestação do serviço que você tenha contratado, ou ainda, nas situações de legítimo interesse; </p>
            <p>d) Autoridades públicas, ou no cumprimento de obrigações da NSC. </p>
        <h2>5. Quais os direitos dos titulares de dados pessoais? </h2>
          <p>Apenas trataremos os seus dados se: </p>
          <p>a) Obtivermos o seu consentimento prévio; </p>
          <p>b) Quando necessário para a execução de contrato ou de procedimentos preliminares relacionados a contrato, nos termos da legislação vigente; </p>
          <p>c) Se o tratamento for necessário para cumprir nossas obrigações legais ou regulatórias; </p>
          <p>d) Se o tratamento for necessário para o exercício regular de nossos direitos em processos litigiosos; </p>
          <p>e) Garantia da prevenção à fraude e à segurança do titular, nos processos de identificação e autenticação de cadastro em sistemas eletrônicos, de acordo com a legislação aplicável. </p>
          <p>f) Para condução do seu atendimento pelo SAC NSC ou quando solicitado pelas autoridades públicas. </p>
          <p>g) Quando necessário para atender aos interesses legítimos do controlador ou de terceiros, nos termos da lei. </p>
          <p>O titular tem direito ao acesso facilitado às informações sobre o tratamento de seus dados, que deverão ser disponibilizadas de forma clara, adequada e ostensiva. O titular dos dados pessoais tem direito a obter, a qualquer momento e mediante requisição: </p>
          <p>h) A confirmação da existência de tratamento; </p>
          <p>i) O acesso aos dados; a correção de dados incompletos, inexatos ou desatualizados; </p>
          <p>j) A anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados em desconformidade com o disposto na legislação; </p>
          <p>l) A portabilidade dos dados a outro fornecedor de serviço ou produto (exceto dados anonimizados), mediante requisição expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos comercial e industrial; </p>
          <p>m) Informação das entidades públicas e privadas com as quais o controlador realizou uso compartilhado de dados; </p>
          <p>n) Informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa; </p>
          <p>o) Revogação do consentimento nos termos da legislação aplicável; peticionar em relação a seus dados; </p>
          <p>p) A eliminação dos dados pessoais tratados com o consentimento do titular, exceto em casos de conformidade com outras legislações ou regulações vigentes. </p>
        <h2>6. Como o titular do dado pode exercer os seus direitos? </h2>
        <p>O titular dos dados pessoais poderá a qualquer momento exercer os seus direitos por meio do E-mail: <a href='mailto:privacidade.dados@gruponc.net.br'>privacidade.dados@gruponc.net.br</a> informando no assunto “Tratamento de Dados Pessoais - NSC” e no corpo do e-mail colocar seu Nome, CPF e descrever detalhadamente o tratamento desejado para seus dados.  </p>
        <h2>7. Por quanto tempo a NSC guarda os seus dados pessoais? </h2>
          <p>A NSC apenas tratará e conservará os dados pessoais pelo período estritamente necessário para a realização das finalidades indicadas nesta Política de Privacidade.</p>
          <p>Os dados pessoais serão tratados e conservados durante a vigência da relação contratual entre a NSC e seus Utilizadores, pelo período que resulte de consentimento prestado pelo titular dos dados ou pelo período necessário para salvaguardar um interesse legítimo da NSC, ou de terceiros. </p>
          <p>Após o percorrido o prazo de conservação e desde que a NSC não esteja obrigada, por imposição legal judicial ou administrativa, a proceder à sua conservação, a NSC eliminará os dados pessoais ou procederá à sua anonimização. </p>
        <h2>8. Como protegemos os seus dados pessoais? </h2>
          <p>Nós dispomos de medidas de segurança em âmbitos físicos, eletrônicos e administrativos, que protegem os usuários do site. Essas medidas de proteção nos auxiliam na prevenção de fraudes e nos acessos não autorizados às informações bem como na manutenção da integridade dos dados. </p>
          <p>Nós colocamos em prática medidas técnicas e administrativas apropriadas para proteger os seus dados pessoais, com um nível adequado de segurança e confidencialidade. </p>
          <p>O objetivo das medidas é o de proteger os seus dados de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação e difusão, bem como contra qualquer outra forma de tratamento ilícito. </p>
          <p>Adicionalmente, ao tratarmos os seus dados, observamos as seguintes obrigações: </p>
          <p>a)	Apenas coletamos e tratamos dados pessoais que sejam adequados, relevantes e não excessivos ao que é extremamente necessário para atingir as finalidades acima referidas; </p>
          <p>b)	Asseguramos que os seus dados pessoais permanecerão atualizados e exatos. Para tanto, poderemos solicitar que confirme os dados que detemos sobre você, bem como, te convidamos a nos informar, espontaneamente, sempre que existir uma alteração sobre as suas circunstâncias pessoais, para que possamos garantir que os seus dados estejam sempre atualizados; e </p>
          <p>c)	Tratar quaisquer dados sensíveis sobre você, fornecidos em conformidade com as normas de proteção de dados aplicáveis e exclusivamente limitado ao que seja necessário para as finalidades acima referidas, sendo os dados acessados e tratados apenas pelo pessoal autorizado, sob nossa responsabilidade ou dos nossos representantes que estejam sujeitos à uma obrigação de sigilo profissional ou confidencialidade. </p>
        <h2>9. Crianças e adolescentes </h2>
          <p>Crianças e adolescentes não podem usar os serviços disponíveis no site sem a autorização de seus pais, responsáveis ​​ou representantes legais, que serão os únicos responsáveis ​​por todos os atos praticados pelos menores durante a navegação nos sites ou disponibilização de dados pessoais por qualquer meio. </p>
        <h2>10. Sites de terceiros </h2>
        <p>A NSC não é responsável pelos sites externos ou de terceiros, que possam ser acessados a partir dos sites da NSC, nem por qualquer conteúdo publicado nos mesmos por quaisquer terceiros, por isso a NSC aconselha aos Utilizadores que ao acessar sites externos ou de terceiros verifiquem os respectivos termos e condições de utilização e política de privacidade.  </p>
        <h1>Política de Cookies </h1>
        <h2>1. Cookies: o que são? </h2>
        <p>As cookies são arquivos que se armazenam no computador do usuário, aqui também chamado de Utilizador, que navega através de Internet e que, em particular, contém um número que permite identificar o computador do usuário. A colocação de cookies ajudará o site a reconhecer o seu dispositivo numa próxima visita. Usamos o termo cookies nesta política para referir todos os arquivos que recolhem informações desta forma. </p>
        <p>A utilização deste tipo de tecnologia no acesso a Sites é uma prática comum, não prejudica os equipamentos dos usuários (computador, smartphone, tablet, etc.) e os diferentes browsers (navegadores) permitem que cada Utilizador tenha a possibilidade de recusar e/ou de eliminar os cookies que já foram armazenados. Os cookies utilizados não recolhem informação que identifica o usuário, no entanto, se já for Utilizador da NSC poderemos monitorar as suas visitas ao site desde que, pelo menos, por uma vez, tenha iniciado a sua navegação a partir de ação voluntária do usuário. </p>
        <h2>2. Por que utilizamos cookies? </h2>
        <p>Os sites da NSC armazenam cookies que são utilizados para melhorar o desempenho e a experiência dos Utilizadores. </p>
        <p>Os cookies armazenados identificam o computador utilizado e permitem melhorar a sua experiência de navegação e utilização, por exemplo, ajudando-nos a associar o seu equipamento às suas preferências ou fornecer-lhe conteúdos que entendemos que podem ser do seu interesse, ou ainda para a liberação de conteúdos exclusivos para assinantes. </p>
        <p>A NSC não assume nenhuma responsabilidade pelo conteúdo das políticas de cookies e de dados de terceiros que possam ser acessadas a partir de navegação inicial nos seus sites. </p>
      </TextPage>
    );
  }
}

export default PrivacyPolicy;