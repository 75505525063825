import Axios from "axios";

const baseUrl = '/api/v1/promotion/'

class PromotionService {

  static async verifyRegistration(registration) {
    return await Axios.post(baseUrl + `verify/${registration}`);
  }

  static async signUpUnisul(request) {
    return await Axios.post(baseUrl + 'signup', request);
  }

}

export default PromotionService;
