import queryString from 'query-string'

const paramNames = {
  clientId: 'client_id',
  redirectUri: 'redirect_uri',
  logoutUri: 'logout_uri',
  requestKey: 'request_key',
  responseType: 'response_type',
  accessToken: 'access_token',
  idToken: 'id_token',
};

class UriHelper {

  static buildQuery(params) {
    const query = Object.keys(params || {})
      .filter(k => params[k])
      .map(k => `${paramNames[k] || k}=${params[k]}`)
      .join('&');

    return query ? `?${query}` : '';
  }

  static buildUri(base, params) {
    const query = this.buildQuery(params);
    return `${base}${query}`;
  }

  static error({ code }) {
    return this.buildUri('/error', { code });
  }

  static login({ clientId, redirectUri, responseType = null, state = null, email = null }) {
    return this.buildUri('/login', { clientId, redirectUri, responseType, state, email });
  }

  static loginLink({ clientId, redirectUri, responseType = null, state = null }) {
    return {
      pathname: '/login',
      search: this.buildQuery({ clientId, redirectUri, responseType, state }),
    };
  }

  static signupLink({ clientId, redirectUri, responseType = null, state = null }) {
    return {
      pathname: '/signup',
      search: this.buildQuery({ clientId, redirectUri, responseType, state }),
    };
  }

  static signupUnisulLink({ clientId, redirectUri, registration, responseType = null, state = null }) {
    return {
      pathname: '/signup-unisul',
      search: this.buildQuery({ clientId, redirectUri, registration, responseType, state }),
    };
  }

  static signupFullLink({ clientId, redirectUri, responseType = null, state = null }) {
    return {
      pathname: '/signup',
      search: this.buildQuery({ clientId, redirectUri, responseType, state, fullSignup:true }),
    };
  }

  static forgotEmailLink({ clientId, redirectUri, responseType = null, state = null }) {
    return {
      pathname: '/forgot-email',
      search: this.buildQuery({ clientId, redirectUri, responseType, state }),
    };
  }

  static forgotPasswordLink({ clientId, redirectUri, responseType = null, state = null }) {
    return {
      pathname: '/forgot-password',
      search: this.buildQuery({ clientId, redirectUri, responseType, state }),
    };
  }

  static confirmPassword({ clientId, redirectUri, responseType = null, state = null, requestKey = null, email = null, code = null }) {
    return this.buildUri('/confirm-password', { clientId, redirectUri, responseType, state, requestKey, email, code });
  }

  static confirmSignup({ clientId, redirectUri, responseType = null, state = null, requestKey = null, email = null, code = null, status = null }) {
    return this.buildUri('/confirm-signup', { clientId, redirectUri, responseType, state, requestKey, email, code, status });
  }

  static confirmSignupUnisul({ clientId, redirectUri, responseType = null, state = null, requestKey = null, email = null, code = null, status = null }) {
    return this.buildUri('/confirm-signup-unisul', { clientId, redirectUri, responseType, state, requestKey, email, code, status });
  }

  static confirmSignupPromotion({ clientId, redirectUri, responseType = null, state = null, requestKey = null, email = null, code = null, status = null }) {
    return this.buildUri('/confirm-signup-promotion', { clientId, redirectUri, responseType, state, requestKey, email, code, status });
  }

  static confirmSignupEvent({ clientId, redirectUri, responseType = null, state = null, requestKey = null, email = null, code = null, status = null }) {
    return this.buildUri('/confirm-signup-event', { clientId, redirectUri, responseType, state, requestKey, email, code, status });
  }

  static changeMail({ clientId, redirectUri, responseType = null, state = null, requestKey = null, email = null, code = null }) {
    return this.buildUri('/change-mail', { clientId, redirectUri, responseType, state, requestKey, email, code });
  }

  static confirmAccount({ clientId, redirectUri, username = null, responseType = null, state = null, requestKey = null, email = null, code = null }) {
    return this.buildUri('/confirm-account', { clientId, redirectUri, responseType, state, requestKey, email, username, code });
  }

  static confirmAccountUnisul({ clientId, redirectUri, username = null, responseType = null, state = null, requestKey = null, email = null, code = null }) {
    return this.buildUri('/confirm-account-unisul', { clientId, redirectUri, responseType, state, requestKey, email, username, code });
  }

  static getParams(url) {
    const query = url.split('?', 2);
    const values = queryString.parse(query.length > 1 ? query[1] : '');
    const result = {};

    for (const i in values) {
      const key = i.replace(/_([a-z])/g, g => g[1].toUpperCase());
      result[key] = values[i];
    }

    if (result.email) {
      result.email = result.email.replace(' ', '+');
    }

    result.responseType = result.responseType
      ? result.responseType.toString().toLowerCase()
      : 'code';

    return result;
  }

  static URLSearchParams(url){
    const params = new URLSearchParams(url.split('?')[1]);
      const parametros = {};

      const keyMapping = {
        'client_id': 'clientId',
        'redirect_uri': 'redirectUri',
        'state' : 'state',
        '_gl': '_gl'
    };

      for (const [key, value] of params.entries()) {
          const newKey = keyMapping[key] || key;
          parametros[newKey] = value;
      }

      return parametros;
  }
 
}

export default UriHelper;