import React from 'react';
import queryString from 'query-string'
import './Error.css';
import Box from '../Components/Box';

class Error extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    this.setState({
      error: this.getErrorDesciption(values.code),
    });
  }

  render() {
    return (
      <Box pageTitle="Erro"
        title="Ocorreu um erro"
        description={<span style={{ fontWeight: 'normal' }}><b>Descrição: </b>{this.state.error}</span >} />
    );
  }

  getErrorDesciption(error) {
    switch (error) {
      case '400':
        return 'Requisição inválida (400).';
      case '401':
        return 'Não autorizado (401).';
      case '404':
        return 'Página não encontrada (404).';
      case 'CODE_EXPIRED':
        return 'Código de verificação expirado';
      default:
        return `Ocorreu um erro (${error})`;
    }
  }
}

export default Error;