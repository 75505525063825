import React, { Component } from 'react';
import AppRouter from './Router'
import './App.css';

class App extends Component {
  render() {
    return (
      <AppRouter />
    );
  }
}

export default App;


