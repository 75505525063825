import React from 'react';
import './Logout.css';
import AuthService from '../Service/AuthService';
import UriHelper from '../Util/UriHelper';
import Loader from '../Components/Loader';
import Box from '../Components/Box';

class Logout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  async componentDidMount() {
    const params = UriHelper.getParams(this.props.location.search);

    try {
      await AuthService.logout({
        clientId: params.clientId,
        redirectUri: params.redirectUri,
        logoutUri: params.logoutUri,
        global: params.global === '1' || params.global === 'true',
      });

      if (params.redirectUri) {
        const uri = UriHelper.login(params);
        this.props.history.push(uri);
      } else {
        const uri = UriHelper.buildUri(params.logoutUri, { state: params.state });
        window.location.href = uri;
      }
    } catch (error) {
      const uri = UriHelper.error({ code: error.response.status });
      this.props.history.push(uri);
    }
  }

  render() {
    return (
      <Box pageTitle="Sair" title="Sair" description="Aguarde...">
        <Loader loaded={this.state.loaded}></Loader>
      </Box>
    );
  }
}

export default Logout;