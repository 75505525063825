class ErrorMessages {

  static getDescription(status, socialNetworkUrl) {
    switch (status) {
      case 'CODE_MISMATCH':
        return 'Código de verificação inválido.';
      case 'EXPIRED_CODE':
        return 'Código de verificação expirado.';
      case 'INVALID_CREDENTIALS':
        return 'Usuário ou senha inválidos'
      case 'INVALID_PASSWORD':
        return 'A senha não atende aos requisitos de complexidade.';
      case 'LIMIT_EXCEEDED':
        return 'Você excedeu o limite de tentativas para recuperar sua senha. Por favor, fale conosco através dos nossos <a class="form-error" href="/help">canais de atendimento</a>.';
      case 'PASSWORD_RESET_REQUIRED':
        return 'Usuário ou senha inválidos';
      case 'USER_NOT_CONFIRMED':
        return 'Sua confirmação segue pendente. Por favor, confira a sua caixa de e-mail e tente novamente'
      case 'USERNAME_EXISTS':
        return 'Já existe um usuário com o e-mail informado.'
      case 'USER_NOT_FOUND':
        return 'Usuário não encontrado.';
      case 'WRONG_PASSWORD':
        return 'A senha não corresponde a senha atual do usuário.'
      case 'USER_MULTIPLE_ACCOUNTS':
        return 'Identificamos mais de um cadastro associado a este e-mail. Por favor, entre em contato conosco através dos <a class="form-error" href="/help">nossos canais de atendimento</a>.';
      case 'USER_FACEBOOK':
      case 'USER_GOOGLE':
        return `Identificamos que seu cadastro está associado a sua conta social. <a class='form-error' href='${(status === 'USER_FACEBOOK' ? socialNetworkUrl.facebookUrl : socialNetworkUrl.googleUrl)}'>Clique aqui para fazer seu login.</a>`;
      case 'ERROR':
        return ' Ocorreu um erro ao recuperar sua senha. Por favor, entre em contato conosco através dos nossos <a class="form-error" href="/help">canais de atendimento</a>.';
      case 'RESEND_ERROR':
        return 'Ocorreu um erro ao reenviar o e-mail. Por favor, tente novamente.';
      case 'CHANGE_EMAIL_ERROR':
        return 'Ocorreu um erro ao alterar o e-mail.';
      case 'USER_ALREADY_CONFIRMED':
        return 'Não é possível fazer a alteração de e-mail para um usuário já confirmado.';
      case 'NON_EXISTENT':
        return 'Token Inválido.';
      case 'INVALID_REGISTRATION':
      case 'ALREADY_REGISTERED':
        return 'Esta matrícula já esta sendo utilizada.';
      case 'INACTIVE':
        return 'Esta matrícula esta inativa.';
      case 'NON_EXISTENT_TOKEN':
        return 'Este token não existe.';
      case 'ALREADY_REGISTERED_TOKEN':
        return 'Este token já esta sendo utilizada.';
      case 'INACTIVE_TOKEN':
        return 'Este token esta inativa.';
      case 'ALREADY_HAS_ALL_ACCESS':
        return 'Já possui assinatura ativa e não participa da promoção.'
      default:
        return status ? `Ocorreu um erro (${status}).` : 'Ocorreu um erro.';
    }
  }
}

export default ErrorMessages;
