import Axios from "axios";
import UriHelper from "../Util/UriHelper";

const baseUrl = '/api/sso/auth/'

class AuthService {

  static async login(request) {
    return await Axios.post(baseUrl + 'login', request);
  }

  static async logout(request) {
    return await Axios.post(baseUrl + 'logout', request);
  }

  static async createCode({ clientId, redirectUri, state, _gl }) {
    state += '?_gl=' + _gl;
    return await Axios.post(baseUrl + 'code', { clientId, redirectUri, state });
  }

  static async createCredentials({ clientId, redirectUri, state }) {
    return await Axios.post(baseUrl + 'credentials', { clientId, redirectUri, state });
  }

  static async getStatus(clientId, redirectUri, logoutUri, state, responseType) {
    const url = UriHelper.buildUri(`${baseUrl}status`, { clientId, redirectUri, logoutUri, state, responseType });
    return await Axios.get(url);
  }

}

export default AuthService;
