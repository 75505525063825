import React, { Component } from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import "./AuthStepper.css";

const styles = theme => ({
  connectorRoot: {
    width: '90%',
    left: 'calc(-56% + 20px)',
    '@media (max-width: 720px)': {
      top: '7px',
      left: 'calc(-56% + 8px)',
    },
  },
  connectorActive: {
    '& $connectorLine': {
      borderColor: '#FF6915',
      marginTop: '8px',
    },
  },
  connectorCompleted: {
    '& $connectorLine': {
      borderColor: '#FF6915',
      marginTop: '8px',
    },
  },
  connectorDisabled: {
    '& $connectorLine': {
      borderColor: '#d8d4d4',
      marginTop: '8px',
    },
  },
  connectorLine: {
    transition: theme.transitions.create('border-color'),
    borderTopWidth: '4px',
    borderColor: '#d8d4d4'
  },
  iconDisabled: {
    width: '33px',
    height: '33px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    border: '3px #d8d4d4 solid',
    color: '#b5b5b5',
    zIndex: 2,
    '@media (max-width: 720px)': {
      width: '25px',
      height: '25px'
    },
  },
  iconActive: {
    width: '33px',
    height: '33px',
    borderRadius: '50%',
    backgroundColor: '#fff',
    border: '3px solid #FF6915',
    color: '#FF6915',
    zIndex: 2,
    '@media (max-width: 720px)': {
      width: '25px',
      height: '25px'
    },
  },
  iconCompleted: {
    backgroundColor: '#FF6915'
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '12px',
    fontFamily: 'Source Sans Pro, sans-serif',
    color: '#b5b5b5',
    marginTop: '8px !important',
    '@media (max-width: 720px)': {
      fontSize: '10px'
    },
  },
  labelActive: {
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    fontFamily: 'Source Sans Pro, sans-serif',
    color: '#FF6915 !important'
  },
  labelCompleted: {
    fontStyle: 'normal',
    fontWeight: 'bold !important',
    fontFamily: 'Source Sans Pro, sans-serif',
    color: '#FF6915 !important'
  }
});

class AuthStepper extends Component {
  constructor(props) {
    super(props);
    this.activeStep = props.activeStep
    this.steps = 4;
  }

  render() {
    const { classes } = this.props;

    const connector = (
      <StepConnector
        classes={{
          active: classes.connectorActive,
          completed: classes.connectorCompleted,
          disabled: classes.connectorDisabled,
          line: classes.connectorLine,
          root: classes.connectorRoot
        }}
      />
    );

    const steppers = [
      'Identificação',
      'Cadastro',
      'Validar e-mail',
      'E-mail Validado'
    ];

    const renderStepIcon = (index) => {
      const renderIcon = (index, showIcon = false) => {
        return (
          <span className='icon-step'>
            {showIcon ?
              <img src='/icons/check_icon.svg' alt="" /> :
              ++index}
          </span>
        );
      };

      const icons = [...Array(this.steps).keys()].map(() => renderIcon(index, (index < this.activeStep)));

      return {
        icon: icons[index],
        classes: {
          iconContainer: this.activeStep >= index ?
            `${classes.iconActive} ${(index < this.activeStep ? classes.iconCompleted : null)}` :
            classes.iconDisabled,
          label: classes.label,
          active: classes.labelActive,
          completed: classes.labelCompleted
        }
      }
    }

    const renderStep = (label, index) => {
      return (
        <Step key={label}>
          <StepLabel style={{ textTransform: 'uppercase' }} {...renderStepIcon(index)}>{label}</StepLabel>
        </Step>
      );
    }

    return (
      <Stepper
        alternativeLabel
        activeStep={this.activeStep}
        connector={connector}
        style={{
          backgroundColor: 'transparent',
          padding: '8px'
        }}
      >
        {steppers.map((label, index) => (
          renderStep(label, index)
        ))}
      </Stepper>
    );
  }
}

AuthStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(AuthStepper);

