import React from 'react';
import { Formik, Form } from 'formik';
import './ConfirmSignup.css';
import ErrorMessages from '../Util/ErrorMessages';
import UriHelper from '../Util/UriHelper';
import Box from '../Components/Box';
import ProfileService from '../Service/ProfileService';
import Logos from '../Components/Logos';

/* eslint-disable  jsx-a11y/anchor-is-valid */
class ConfirmSignup extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      params: {},
      username: '',
      email: '',
      error: '',
    };

    this.renderForm = this.renderForm.bind(this);
    this.handleResendEmail = this.handleResendEmail.bind(this);
    this.handleConfirmedAccount = this.handleConfirmedAccount.bind(this);
    this.formatEmail = this.formatEmail.bind(this);
    this.handleChangeMail = this.handleChangeMail.bind(this);
  }

  async componentDidMount() {
    const params = UriHelper.getParams(this.props.location.search);
    const userData = ProfileService.getUserData();
    if (!!!userData || !!!userData.user || !!!userData.statusSign) {
      const queryParams = { ...params };
      const url = UriHelper.signupLink(queryParams);
      this.props.history.push(url);
      return;
    }

    this.setState({
      error: '',
      params,
      username: userData.user,
      email: userData.email
    });

    if (!!params.status) {
      await this.handleChangeMailFeedback(params.status);
    }
  }

  render() {
    return (
      <Box
        className="confirm-signup"
        pageTitle="Confirmação de usuário"
        message={this.state.message}
        title="Verifique seu e-mail"
        description="Para continuar é necessário efetuar a validação do seu e-mail. <br />
        Confira na sua caixa de entrada:">
        <Formik
          render={this.renderForm} />
        <div style={{ marginTop: '20px' }}>
          <Logos />
        </div>
      </Box>
    );
  }

  renderForm({ isSubmitting }) {
    return (
      <Form className="form">
        <div className="email-container">
          <span className="email-text">{this.formatEmail()}</span>
          <button className="btn" type="button" onClick={this.handleChangeMail} disabled={isSubmitting}>Alterar</button>
        </div>
        <hr className="line" />
        <button className="btn-submit" onClick={this.handleConfirmedAccount} type="button" disabled={isSubmitting}>
          {isSubmitting ? 'Aguarde' : 'Já confirmei o e-mail'}
        </button>
        <hr className="line" />
        <div className="resend-container">
          Ainda não recebeu o e-mail?  <br />
          Confira sua caixa de spam ou <a onClick={this.handleResendEmail} className="form-error" href="#">REENVIAR O E-MAIL</a>.
        </div>
      </Form>
    );
  }

  handleChangeMail() {
    const url = UriHelper.changeMail({ ...this.state.params });
    this.props.history.push(url);
  }

  async handleResendEmail(params, quiet = false) {
    const { data } = await ProfileService.sendSignUpCode({
      user: !params.user ? this.state.username : params.user,
      clientId: !params.data ? this.state.params.clientId : params.data.clientId,
      redirectUri: !params.data ? this.state.params.redirectUri : params.data.redirectUri,
      state: !params.data ? this.state.params.state : params.data.state
    });
    if (!quiet) {
      this.cleanMessages();
      this.setMessage(data.status);
    }
  }

  cleanMessages() {
    this.setState({
      message: null
    });
  }

  async handleConfirmedAccount() {
    let url;
    const { data } = await ProfileService.confirmedUser({ user: this.state.username });
    if (data.status === 'CONFIRMED') {

      const params = {
        ...this.state.params,
        username: this.state.username,
        email: this.state.email
      };

      if (params.isUnisul) {
        url = UriHelper.confirmAccountUnisul(params);
      } else {
        url = UriHelper.confirmAccount(params);
      }

      this.props.history.push(url);
    } else {
      this.cleanMessages();
      this.setMessage(data.status, null);
    }
  }

  async handleChangeMailFeedback(status) {
    if (status === 'SUCCESS') {
      const params = UriHelper.getParams(this.props.location.search);
      const userData = ProfileService.getUserData();
      await this.handleResendEmail({ data: { ...params }, user: userData.user }, true);
    }

    this.cleanMessages();
    this.setMessage(status, status === 'SUCCESS' ?
      'E-mail alterado com sucesso. Verifique sua caixa de entrada' : null);
  }

  formatEmail() {
    const userData = ProfileService.getUserData();
    let emailSplitted = userData.email.split('@');
    let domainName = emailSplitted[1].split('.').shift();
    let extension = emailSplitted[1].split('.');
    extension.shift();
    return `${emailSplitted[0].substring(0, 3)}*************@${emailSplitted[1].substring(0, 1)}******${domainName.slice(-1)}.${extension.join('.')}`;
  }

  setMessage(status, message) {
    if (!!message) {
      this.setState({ message: { status: status, message: message } });
    } else {
      this.setState({
        message: {
          status: status,
          message: status === 'SUCCESS' ? 'E-mail reenviado! Se o problema continuar, verifique sua caixa de spam.' : ErrorMessages.getDescription(status)
        }
      });
    }
  }
}

export default ConfirmSignup;
