
import React from 'react';
import { Link } from 'react-router-dom';
import './CustomLink.css';

class CustomLink extends React.Component {

  render() {
    return (
      <div className={`custom-link ${!!this.props.inline ? 'inline' : ''}`} style={{ textAlign: this.props.align || "left", fontSize: this.props.size || "1rem" }}>
        <span hidden={!this.props.message}>{this.props.message} </span>
        <a hidden={!this.props.url} href={this.props.url} target={this.props.target || ''}>{this.props.linkText}</a>
        {!this.props.url && (<Link to={this.props.to}>{this.props.linkText}</Link>)}
      </div>
    );
  }

}

export default CustomLink;
