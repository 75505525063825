import React from 'react';
import './Help.css';
import TextPage from '../Components/TextPage';

class Help extends React.Component {

  render() {
    return (
      <TextPage title="Precisa de ajuda?">
        <h3>Fale conosco através dos canais de atendimento:</h3>
        <ul>
          <li>E-mail: <a href="mailto:atendimento.nsc@nsc.com.br">atendimento.nsc@nsc.com.br</a></li>
          <li>Portal do Assinante: <a href="https://www.assinantensc.com.br" rel="noopener noreferrer" target="_blank">www.assinantensc.com.br</a></li>
          <li>Whatsapp : <a href='https://api.whatsapp.com/send?phone=5508006444001'> 0800 644 4001 </a> </li>
        </ul>
        <h3>Fones (atendimento de segunda a sexta das 8h às 16h e feriados das 8h às 12h):</h3>
        <ul>
          <li>0800 644 4001 - Todas as Cidades</li>
        </ul>
      </TextPage>
    );
  }
}

export default Help;
