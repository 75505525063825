import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import './ForgotEmail.css';
import FormError from '../Components/FormError';
import Validation from '../Util/Validation';
import UriHelper from '../Util/UriHelper';
import ProfileService from '../Service/ProfileService';
import Box from '../Components/Box';
import MaskedInput from 'react-text-mask'
import ErrorMessages from '../Util/ErrorMessages';
import Separator from '../Components/Separator';
import PasswordRecoveryService from '../Service/PasswordRecoveryService';

class ForgotEmail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      params: {},
      error: '',
      siteKey: '',
      emailFound: false,
      multipleEmailFound: false,
      notFound: false,
      username: '',
      email: '',
      isSendPasswordRecovery: false,
      sendedPasswordRecovery: false
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.sendPasswordRecovery = this.sendPasswordRecovery.bind(this);
  }

  async componentDidMount() {
    const params = UriHelper.getParams(this.props.location.search);
    this.setState({ params });
  }

  render() {
    let box = (
      <Box
        className="forgot-email"
        pageTitle="Buscar E-mail"
        title="Conta "
        logo={true}
        showImage={true}
        showBackButton={true}
        description="Informe seu CPF para  buscar o e-mail cadastrado na sua conta">
        <Formik
          onSubmit={this.onFormSubmit}
          render={this.renderForm}
          initialValues={{ username: '', document: '', option: 'document' }}
          validationSchema={this.getFormValidationSchema}
        />
      </Box>
    );

    if (this.state.emailFound) {
      box = (
        <Box
          className="forgot-email"
          pageTitle="Buscar E-mail"
          title="Conta "
          logo={true}
          showImage={true}
          showBackButton={true}>
          <spam className="spam-alert-one">Encontramos o seu cadastro!</spam>
          <spam className="spam-alert-two">Protegemos o seu e-mail para sua segurança:</spam>
          <spam className="spam-email">{this.state.email}</spam>
          <spam className="spam-alert-three custom-link">Continua com problemas? Entre em contato pelos <a href="/help">canais de atendimento</a>.</spam>
          <Separator />
          <spam className="spam-alert-last">Caso não lembre da sua senha, clique no botão abaixo</spam>
          <button
            className="btn-submit-forgot-email"
            onClick={this.sendPasswordRecovery}
            disabeld={this.state.isSendPasswordRecovery || this.state.sendedPasswordRecovery}>
            {this.state.isSendPasswordRecovery ? "Enviado E-mail.." : this.state.sendedPasswordRecovery ? "E-mail Enviado" : "Redefinir Minha Senha"}
          </button>
        </Box>
      );
    }

    if (this.state.multipleEmailFound) {
      box = (
        <Box
          className="forgot-email"
          pageTitle="Buscar E-mail"
          title="Conta "
          logo={true}
          showImage={true}
          showBackButton={true}>
          <spam className="spam-alert-one">Você possui mais de uma Conta NSC Total.</spam>
          <spam className="spam-alert-two">Para identificar o seu e-mail de cadastro, entre em contato conosco pelos canais de atendimento:</spam>
          <spam className="spam-fone-email">0800 644 4001</spam>
          <spam className="spam-alert-or">ou</spam>
          <spam className="spam-fone-email">atendimento@nsc.com.br</spam>
          <a href={UriHelper.login(this.state.params)}>
            <button className="btn-submit" type="submit">Voltar</button>
          </a>
        </Box>
      );
    }

    return box;
  }

  renderForm({ errors, touched, isSubmitting, values, setFieldValue }) {
    return (
      <Form className="form">
        <div className="form-group">
          <label>CPF</label>
          <ErrorMessage name="document" component="div" className="error-message" />
          <Field
            type="text"
            name="document"
            render={({ field }) => (
              <MaskedInput
                {...field}
                type="text"
                placeholder="Digite seu CPF ou CNPJ"
                disabled={values.option !== 'document'}
                className={errors.document && touched.document ? 'error' : ''}
                mask={(v, vv) => v.replace(/[./\-_]+/g, '').length > 11
                  ? [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                  : [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
              />
            )} />
        </div>
        <div style={{textAlign: 'center', margin:'12px -4px 7px'}} hidden={!this.state.notFound} className="form-error">
          Não encontramos seu cadastro. Caso esteja tendo dificuldades, entre em contato pelos <a href="/help">canais de atendimento</a>.
        </div>
        <FormError message={this.state.error} />
        <button className="btn-submit" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Aguarde' : 'Buscar'}
        </button>
      </Form >
    );
  }

  getFormValidationSchema() {
    return yup.object().shape({
      option: yup.string(),
      username: yup.string().when('option', {
        is: 'username',
        then: Validation.username({ required: true }),
      }),
      document: yup.string().when('option', {
        is: 'document',
        then: Validation.document({ required: true }),
      }),
    });
  }

  async onFormSubmit(values, { setSubmitting }) {
    try {

      const document = values.option === 'document' ? values.document.replace(/[^\d]/g, '') : null;
      let response = await ProfileService.forgotEmail({document:document});
      console.log(response);
      if (response.data.status === "SUCCESS") {
        this.setState({
          emailFound: true,
          username: response.data.username,
          email: response.data.email
        });
      } else if (response.data.status === "NOT_FOUND") {
        this.setState({ notFound: true });
      } else if (response.data.status === "FOUND_MULTIPLE") {
        this.setState({ multipleEmailFound: true });
      }

    } catch (error) {
      this.setState({
        error: error.response.status === 404 ? null : ErrorMessages.getDescription(error.response.status),
        notFound: error.response.status === 404,
      });
    }

    setSubmitting(false);
  }

  async sendPasswordRecovery() {
    try {
      this.setState({
        isSendPasswordRecovery: true
      });
      await PasswordRecoveryService.sendCode({
        username: this.state.username,
        clientId: this.state.params.clientId,
        redirectUri: this.state.params.redirectUri,
      });

      this.setState({
        sendedPasswordRecovery: true
      });
    } catch (error) {
      this.setState({
        error: ErrorMessages.getDescription(error.response.data.status, this.state.socialNetwork),
      });
    }

    this.setState({
      isSendPasswordRecovery: false
    });
  }
}

export default ForgotEmail;
