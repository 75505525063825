
import React from "react";
import './Checkbox.css';
import { ErrorMessage } from "formik";

const Checkbox = ({
  field: { name },
  form: { errors, values, touched, setFieldValue },
  label,
  size,
}) => {
  const id = name + '-' + Math.random().toString(36).substr(2, 4);
  const clazz = [
    'checkbox',
    size || '',
    errors[name] && touched[name] ? 'error' : ''
  ].join(' ').trim();

  return (
    <span className={clazz}>
      <input
        name={name}
        id={id}
        type="checkbox"
        checked={values[name]}
        onChange={e => setFieldValue(name, e.target.checked)}
      />
      <label htmlFor={id}>
        <span className="check"></span> {label}
      </label>
      <ErrorMessage name={name} component="div" className="error-message" />
    </span>
  );
};

export default Checkbox;