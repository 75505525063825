import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import './ForgotPassword.css';
import PasswordRecoveryService from '../Service/PasswordRecoveryService';
import AuthService from '../Service/AuthService';
import FormError from '../Components/FormError';
import Validation from '../Util/Validation';
import ErrorMessages from '../Util/ErrorMessages';
import UriHelper from '../Util/UriHelper';
import Box from '../Components/Box';

class ForgotPassword extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      params: {},
      error: '',
      siteKey: '',
    };

    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  async componentDidMount() {
    const params = UriHelper.getParams(this.props.location.search);
    const { data } = await AuthService.createCode(params);
    this.setState({
      params: params,
      socialNetwork: { facebookUrl: data.facebookUrl, googleUrl: data.googleUrl },
    });
  }

  render() {
    return (
      <Box
        pageTitle="Recuperar senha"
        title="Recupere a senha da sua conta NSC"
        description="Informe seu e-mail para receber o código de verificação"
        showImage={true}
        showBackButton={true}>
        <Formik
          onSubmit={this.onFormSubmit}
          render={this.renderForm}
          initialValues={{ username: '' }}
          validationSchema={this.getFormValidationSchema}
        />
      </Box>
    );
  }

  renderForm({ errors, touched, isSubmitting }) {
    return (
      <Form className="form">
        <div className="form-group">
          <label>E-mail</label>
          <ErrorMessage name="username" component="div" className="error-message" />
          <Field
            type="text"
            name="username"
            autoCorrect="off"
            autoCapitalize="off"
            placeholder="Digite seu e-mail"
            className={errors.username && touched.username ? 'error text-lower-case' : 'text-lower-case'} />
        </div>
        <FormError message={this.state.error} />
        <button className="btn-submit" type="submit" disabled={isSubmitting}>
          {isSubmitting ? 'Aguarde' : 'Enviar e-mail'}
        </button>
      </Form>
    );
  }

  getFormValidationSchema() {
    return yup.object().shape({
      username: Validation.email({ required: true })
    });
  }

  async onFormSubmit(values, { setSubmitting }) {
    try {
      const result = await PasswordRecoveryService.sendCode({
        username: values.username,
        clientId: this.state.params.clientId,
        redirectUri: this.state.params.redirectUri,
      });

      const confirmUrl = UriHelper.confirmPassword({
        ...this.state.params,
        requestKey: result.data.requestKey,
      });

      console.log({
        ...this.state.params,
        requestKey: result.data.requestKey,
      });


      this.props.history.push(confirmUrl);
    } catch (error) {
      this.setState({
        error: ErrorMessages.getDescription(error.response.data.status, this.state.socialNetwork),
      });
    }

    setSubmitting(false);
  }

}

export default ForgotPassword;
